
import React from "react";
import Table from "../../components/Table2";
import { getNewsLetter } from "../dataService/DataService";

import { useQuery } from "react-query";
import moment from "moment";



const Newsletter = () => {




    const getCookie = (name) => {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Check if this cookie is the one we are looking for
            if (cookie.startsWith(name + "=")) {
                // Return the value of the cookie
                return cookie.substring(name.length + 1);
            }
        }
        // Return null if the cookie is not found
        return null;
    };
    const websiteIdFromCookie = getCookie("websiteId");


    const newsletterData = useQuery(
        ["getNewsLetter", +websiteIdFromCookie],
        () => getNewsLetter(websiteIdFromCookie)
    );










    const columns = React.useMemo(
        () => [
            { Header: "Sr. No", accessor: "srNo" },

         
            { Header: "Email", accessor: "email", Cell: ({ row }) => `${row.original.email}`, },
            { Header: "CREATED AT", accessor: "last_requested_on", Cell: ({ row }) => (
                <span className="" style={{ width: "200px" }}>
                  {moment(row.original.last_requested_on).format(
                    "MMM D, YYYY h:mm A [GMT]Z"
                  )}
                </span>
              )}, 

        ],
        []
    );






    return (
        <>

            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">

                        <div className="left-header">
                            <h4 className="fw-normal m-0">NEWSLETTER</h4>
                        </div>
                    </div>

                    <div className="card mt-lg-5 mt-4">
                        <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                            <h5 className="m-0 fw-medium">All NEWSLETTER</h5>
                        </div>
                      
                        {
                            newsletterData.data &&
                            <Table data={newsletterData?.data?.data?.data} key={newsletterData?.data?.data?.data.newsletter_id} columns={columns} />
                        }

                    </div>
                </div>
            </div>




          

        </>
    );
};

export default Newsletter;
