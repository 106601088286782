import React, { useState } from "react";
import { getAllBedType } from "../../services/service";

import { useQuery } from "react-query";

import OccupancyDropDown from "./OccupancyDropDown";
import BedTypes from "./BedTypes";
function SpecificationsTab({
  props,
  handleChange,
  values,
  setFieldValue,
  setValues,
  initialBedTypeData,
  errors,
  error,
  touched,
  initialValues,
  duplicateIndexes,
  setDuplicateIndexes,
}) {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleRadioChange = (e, setFieldValue) => {
    const newValue = e.target.value;
    if (newValue === "no") {
      setFieldValue("poolSize", "0");
    }
  };
  const [dropdowns, setDropdowns] = useState([]);
  let AllBedType = useQuery("getAllBedType", getAllBedType);
  // const handleChangeBedTypeChange =()=>{

  // }
  const handleChangeBedTypeChange = (event, dropdownKey) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        bedTypeId: {
          ...prevValues.bedTypeId,
          [dropdownKey]: event.target.value,
        },
      };
    });
  };

  ///adult count
  const adultIncrementBtn = (event) => {
    event.preventDefault();

    setValues({
      ...values,
      adultCount: +values.adultCount + 1,
    });
  };
  const adultIncrementBtn1 = (event) => {
    event.preventDefault();

    setValues({
      ...values,
      adultCount1: +values.adultCount1 + 1,
    });
  };
  const adultDecrementBtn = (e) => {
    e.preventDefault();

    if (values.adultCount > 1) {
      setValues({
        ...values,
        adultCount: +values.adultCount - 1,
      });
    }
  };
  const adultDecrementBtn1 = (e) => {
    e.preventDefault();

    if (values.adultCount1 > 1) {
      setValues({
        ...values,
        adultCount1: +values.adultCount1 - 1,
      });
    }
  };
  /* End adult count */

  /* Start children count*/
  const childrenIncrement = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      childrenCount: +values.childrenCount + 1,
    });
  };
  const childrenIncrement1 = (event) => {
    event.preventDefault();
    setValues({
      ...values,
      childrenCount1: +values.childrenCount1 + 1,
    });
  };
  const childrenDecrement = (event) => {
    event.preventDefault();
    if (values.childrenCount > 0) {
      setValues({
        ...values,
        childrenCount: +values.childrenCount - 1,
      });
    }
  };
  const childrenDecrement1 = (event) => {
    event.preventDefault();
    if (values.childrenCount1 > 0) {
      setValues({
        ...values,
        childrenCount1: +values.childrenCount1 - 1,
      });
    }
  };

  const handleAddDropdown = (index) => {
    const newDropdownKey = index.toString();
    setDropdowns((prevDropdowns) => [
      ...prevDropdowns,
      {
        key: Math.random() * 1000,
        options: "",

        onDelete: () => handleRemoveDropdown(newDropdownKey),
      },
    ]);
  };

  const handleRemoveDropdown = (dropdown) => {
    const parentDiv = document.querySelector(
      `.added-specifications[data-key="${dropdown}"]`
    );
    if (parentDiv) {
      parentDiv.remove();
    }

    const data = values.bedTypeId;
    const keyToRemove = dropdown; // You might get the key dynamically or hardcode it

    // Check if the key exists before removing
    if (data.hasOwnProperty(keyToRemove)) {
      delete data[keyToRemove];
    } else {
    }
  };

  return (
    <>
      <div className="edit-mein-info">
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="top-header-edit">
            <h5>Specifications</h5>
          </div>
        </div>

        <div className="property-facilities mt-4 mb-4">
          <div className="occupancy-row">
            <div className="row">
              <div className="col-12">
                <label htmlFor="FacilityOne" className="form-label">
                  <i className="fa-solid fa-user me-2"></i>Occupancy{" "}
                  <span className="mandatory">*</span>
                </label>
              </div>
              <div className="col mb-4">
                <div className="facility-view specification-dropdown d-flex align-items-center">
             
                </div>
              </div>
              <div className="facility-view specification-dropdown  align-items-center">
                <OccupancyDropDown
                  values={values}
                  initialValues={initialValues}
                  showDropdown={true}
                  setFieldValue={setFieldValue}
                  setValues={setValues}
                  duplicateIndexes={duplicateIndexes}
                  setDuplicateIndexes={setDuplicateIndexes}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3">
              <div className="">
              
              </div>
            </div>
            
        

            <BedTypes
              values={values}
              initialValues={initialValues}
              showDropdown={true}
              setFieldValue={setFieldValue}
              setValues={setValues}
              AllBedType={AllBedType}
              errors={errors}
              error={error}
              touched={touched}
            />
            <div className="col-xl-6 mb-4">
              <div className="facility-view">
                <label htmlFor="FacilityOne" className="form-label">
                  <i className="fa-solid fa-maximize me-2"></i> Area (sq. m.)
                  <span className="mandatory">*</span>
                </label>
                <input
                  type="text"
                  className="form-control py-3 shadow-none px-4"
                  placeholder="Area Size"
                  name="area"
                  id=""
                  value={values.area}
                  onChange={handleChange}
                  maxLength={20}
                />
                {/* <!-- /facility-view --> */}
              </div>
              {errors.area && touched.area ? (
                <p className="form-error text-danger">{errors.area}</p>
              ) : error.area ? (
                <p className="form-error text-danger">{error.area}</p>
              ) : null}
            </div>

            <div className="col-md-12 mb-4">
              <div className="facility-view">
                <label htmlFor="FacilityOne" className="form-label">
                  <i className="fa-solid fa-person-swimming me-2"></i> Private
                  Pool (sq. m.)
                </label>
                <div className="d-flex align-items-center">
                  <div className="checkboxes mb-3 mt-3">
                    <label htmlFor="PrivatePoolY">
                      <input
                        id="PrivatePoolY"
                        type="radio"
                        name="privatepool"
                        value="yes"
                        checked={
                          values.privatepool === "yes" || values.poolSize > 0
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleRadioChange(e, setFieldValue);
                        }}
                      />{" "}
                      Yes
                    </label>
                    <label>
                      <input
                        id="PrivatePoolN"
                        type="radio"
                        name="privatepool"
                        value="no"
                        checked={
                          values.privatepool !== "yes" ||
                          values.privatepool === "no" ||
                          values.poolSize === 0
                        }
                        onChange={(e) => {
                          handleChange(e);
                          handleRadioChange(e, setFieldValue);
                        }}
                      />{" "}
                      No
                    </label>
                  </div>
                  {/* <!-- /facility-view --> */}
                  {(values.privatepool === "yes" || values.poolSize > 0) && (
                    <div className="yes-input ms-4">
                      <input
                        type="text"
                        placeholder="Enter pool size"
                        className="form-control py-3 shadow-none px-4"
                        name="poolSize"
                        value={values.poolSize}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SpecificationsTab;
