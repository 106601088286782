import React, { useEffect, useRef, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import * as querydata from "../dataService/DataService";
import backarrow from "../../assets/images/back-arrow.svg";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getRestaurantType } from "../dataService/DataService";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumView from "../manageAlbum/AlbumViewForDinning";
import MultipleRestaurantTag from "./MultiRestaurantTag";
// import RestaurantDetail from "./RestaurantDetail";
import RestaurantDetailEdit from "./RestaurantDetailEdit";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import pdfImg from "../../assets/images/pdf-doc.svg";
import RestaurentTags from "./RestaurentTags";
import { useQueryClient } from "react-query";

export const RestaurantSchema = Yup.object().shape({
  RestaurantName: Yup.string().required("Please enter restaurant name"),

  // RestaurantTypeId: Yup.number()
  //   .typeError("Please select restaurant type")
  //   .required("Please select restaurant type"),
  RestaurantDescription: Yup.string().required(
    "Please enter restaurant description"
  ),
  RestaurantTagLine: Yup.string().required("Please enter restaurant Tagline"),
  dataArray: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("This field requires a heading"),
      tagDescription: Yup.string().required(
        "This field requires a description"
      ),
      // RestaurantTags: Yup.array().of(Yup.string().required("Each tag feature must have a description"))
      // tagFeatures: Yup.array().of(
      //   Yup.object().shape({
      //     RestaurantTags: Yup.string().required("this field requrired")
      //   })

      // )
      // tagFeatures: Yup.string().required("This field requires a description"),
      tagFeatures: Yup.array()
        .of(Yup.string().required("Please add atleast one tag Feature").trim())
        .min(1, "At least one tag feature is required"),
    })
  ),
});
export default function EditDining() {
  const { id } = useParams();
  const getSingleRestaurant = useQuery(
    ["Restaurant", id],
    () => querydata.getSingleRestaurant(id),
    {
      refetchOnWindowFocus: false,
    }
  );
  const [editsection, setsectionImage] = useState();

  let AllRestaurantTypeId = useQuery("getRestaurant", getRestaurantType);
  const navigate = useNavigate();
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const [maincroppedSecImage, setmainCroppedSecImage] = useState(null);
  const [maincroppedThirdImage, setmainCroppedThirdImage] = useState(null);
  const [maincroppedSecFile, setmainCroppedSecFile] = useState(null);
  const [maincroppedThirdFile, setmainCroppedThirdFile] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkValForSingleImg3, setValForSingleImg3] = useState("");
  const [checkValForIcon, setValForIcon] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [currentFileId, setCurrentFileId] = useState("");
  const [mainCroppedIcon, setMainCroppedIcon] = useState(null);
  const [mainCroppedIconFile, setmainCroppedIconFile] = useState(null);
  const [icon, setIcon] = useState(null);
  const [BannerImage, setBannerImage] = useState(null);
  const [maincroppedBannerImage, setMainCroppedBannerImage] = useState(null);
  const [mainCroppedBannerImageFile, setmainCroppedBannerImageFile] =
    useState(null);
  const [checkValForBannerImage, setValForBannerImage] = useState(null);
  const [settingValue, setSettingValue] = useState("");
  const [imgArray, setImgArray] = useState(null); //use to get the index of the selected detail array Image
  const closeCropMediaModal = () => setCropMediaModal(false);

  const showCropMediaModal = (id, val, index) => {
    setCurrentFileId(id);
    if (val === "firstImage") {
      setValForSingleImg(val);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue(getCardImageAspectRatio?.data?.data?.setting_value);
    }
    if (val === "secondImage") {
      setMultipleImageSection(val);
      setValForSingleImg("");
      setValForIcon(null);
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue(getAspectRatio?.data?.data?.setting_value);
    }
    if (val === "thirdImage") {
      setImgArray(index);
      setValForSingleImg3(val);
      setMultipleImageSection(null);
      setValForSingleImg(null);
      setValForBannerImage(null);
      setSettingValue(getAspectRatio?.data?.data?.setting_value);
    }
    if (val === "BannerImage") {
      setValForBannerImage(val);
      setValForSingleImg(null);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setSettingValue(getInnerImageAspectRatio?.data?.data?.setting_value);
    }
    if (val === "restaurantIcon") {
      setValForIcon(val);
      setValForSingleImg(null);
      setValForSingleImg3(null);
      setMultipleImageSection(null);
      setSettingValue(getAspectRatio?.data?.data?.setting_value);
    }
    setCropMediaModal(true);
  };
  const queryClient = useQueryClient();

  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "dining_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_image_dimension")
  );
  const getInnerImageAspectRatio = useQuery(
    ["AspectRatio", "dining_inner_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_inner_image_dimension")
  );

  const getCardImageAspectRatio = useQuery(
    ["AspectRatio", "dining_card_image_dimension"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_card_image_dimension")
  );
  const handleRemoveImage = (e, image) => {
    image = undefined;
    // Handle removing the image
    if (e) {
      setImage(undefined);
      setmainCroppedImage(undefined);
    }
  };
  const handleBannerImage = (e, image) => {
    image = undefined;
    // Handle removing the image
    if (e) {
      setBannerImage(undefined);
      setMainCroppedBannerImage(undefined);
    }
  };
  const handleSecRemoveImage = (e) => {
    // Handle removing the image
    if (e) {
      setmainCroppedSecFile(null);
      setImage2(undefined);
      setmainCroppedSecImage(undefined);
    }
  };
  const handleRemoveIcon = (e) => {
    // Handle removing the image
    setmainCroppedIconFile(null);
    setMainCroppedIcon(null);
    if (e) {
      setIcon(null);
      setMainCroppedIcon(null);
    }
  };

  const handleThirdRemoveImage = (e, sectionId, index) => {
    // Handle removing the image
    setsectionImage(sectionId);
    e.preventDefault();
    if (maincroppedThirdFile !== null) {
      const data = { ...maincroppedThirdFile };
      data[imgArray] = null;
      setmainCroppedThirdFile(data);
    }

    if (e) {
      setImage3((prevData) => {
        const updatedData = { ...prevData };

        delete updatedData[sectionId];
        return updatedData;
      });
    }
  };

  const [restaurantName, setRestaurantName] = useState(
    getSingleRestaurant?.data?.data?.data[0].restaurant_name || ""
  );
  const [restaurantDescription, setRestaurantDescription] = useState(
    getSingleRestaurant?.data?.data?.data[0].restaurant_description || ""
  );
  const [restaurantTypeId, setRestaurantTyprId] = useState(
    getSingleRestaurant?.data?.data?.data[0].restaurant_type_id
  );

  const [restaurandDetailDataArray, setRestaurandDetailDataArray] = useState(
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataArrays = getSingleRestaurant?.data?.data?.data.find(
          (d) => d.detailData
        )?.detailData;

        // Assuming features is an array inside each restaurant object
        const newArray =
          dataArrays?.map((restaurant) => ({
            id: restaurant.restaurant_detail_id,
            name: restaurant.heading,
            tagImage: restaurant.image_path,
            tagHeading: restaurant.heading,
            tagDescription: restaurant.description,
            tagFeatures: restaurant.features.map((f) => f.features),
          })) || [];

        // Retrieve previous data from sessionStorage
        const storedDataArray = sessionStorage.getItem("dataArray");
        if (storedDataArray) {
          // Filter out already existing data from fetched data
          const filteredNewArray = newArray.filter(
            (item) =>
              !JSON.parse(storedDataArray).find(
                (existingItem) => existingItem.id === item.id
              )
          );
          // Merge previous data with newly fetched data
          const mergedArray =
            JSON.parse(storedDataArray).concat(filteredNewArray);
          setRestaurandDetailDataArray(mergedArray);
        } else {
          // Set the fetched data if no previous data exists
          setRestaurandDetailDataArray(newArray);
        }
      } catch (error) {}
    };

    fetchData();
  }, [getSingleRestaurant?.data]);

  // Save dataArray to sessionStorage whenever it changes
  useEffect(() => {
    sessionStorage.setItem(
      "dataArray",
      JSON.stringify(restaurandDetailDataArray)
    );
  }, [restaurandDetailDataArray]);

  // Retrieve dataArray from sessionStorage on component mount
  useEffect(() => {
    const storedDataArray = sessionStorage.getItem("dataArray");
    if (storedDataArray) {
      setRestaurandDetailDataArray(JSON.parse(storedDataArray));
    }
  }, []);
  console.log(getSingleRestaurant?.data?.data?.data, "restaurant");
  const [initialValues, setInitialValues] = useState({
    RestaurantName:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_name || "",
    RestaurantDescription:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_description || "",
    RestaurantTypeId:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id || "",
    RestaurantTagLine:
      getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline || "",
    RestaurantTags:
      getSingleRestaurant?.data?.data?.data[0]?.tags?.map(
        (tag) => tag.restaurant_tag
      ) || [],
    dataArray: restaurandDetailDataArray,
  });

  // const convertImageToBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader(); reader.onloadend = () => {
  //       const base64String = reader.result.split(',')[1];
  //       resolve(base64String);
  //     }; reader.onerror = (error) => {
  //       reject(error);
  //     }; reader.readAsDataURL(file);
  //   });
  // };

  useEffect(() => {
    setValues({ ...initialValues });

    const originalUrl = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.restaurant_image
    )?.restaurant_image;

    setmainCroppedImage(originalUrl);
    const originalUrl2 = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.restaurant_menu_image
    )?.restaurant_menu_image;
    if (originalUrl2 === "null") {
      setmainCroppedSecImage(null);
    } else {
      setmainCroppedSecImage(originalUrl2);
    }

    const originalUrl3 = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.restaurant_icon
    )?.restaurant_icon;
    if (originalUrl3 === "null") {
      setMainCroppedIcon(null);
    } else {
      setMainCroppedIcon(originalUrl3);
    }

    const bannerImageUrl = getSingleRestaurant?.data?.data?.data.find(
      (d) => d.inner_banner_image
    )?.inner_banner_image;
    setMainCroppedBannerImage(bannerImageUrl);
    console.log(bannerImageUrl, "bannerImageURL");

    const detailImageObject = (
      getSingleRestaurant?.data?.data?.data[0]?.detailData || []
    ).reduce((accumulator, data, index) => {
      accumulator[`section_${data.restaurant_detail_id}`] = data.image_path;
      return accumulator;
    }, {});

    setImage3(detailImageObject);
  }, [getSingleRestaurant?.data]);

  const hasDecimalPoint = (number) => {
    return number % 1 !== 0;
  };
  let base64data = "";

  const [load, setload] = useState(true);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RestaurantSchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append(
        "restaurantTypeId",
        values.RestaurantTypeId === "---Select---"
          ? ""
          : values.RestaurantTypeId
      );
      formData.append("restaurantDescription", values.RestaurantDescription);
      formData.append("restaurantName", values.RestaurantName);

      // formData.append("restaurantTagline", values.RestaurantTagLine)
      formData.append("restaurantTagline", values.RestaurantTagLine);
      if (values.dataArray) {
        values.dataArray.map((data, index) => {
          let checkUpdate = false;
          if (data.tagImage.length > 500) {
            checkUpdate = true;
          }

          const detailFeatures = Object.values(data.tagFeatures);
          const imgKey = Object.keys(image3);
          let fileKey = "";
          if (maincroppedThirdFile !== null) {
            fileKey = Object.keys(maincroppedThirdFile);
          }

          let resultArray = [];
          let matchCount = 0;
          let nonMatchCount = 0;

          for (const element of imgKey) {
            if (fileKey.includes(element)) {
              matchCount++;
              resultArray.push({ [`${element}`]: true });
            } else {
              nonMatchCount++;
              resultArray.push({ [`${element}`]: false });
            }
          }

          const filteredArray = resultArray.filter(
            (item) => Object.values(item)[0] === true
          );

          const isNewSectionImageTrue = filteredArray.some(
            (item) =>
              Object.keys(item)[0] === `section_${data.id}` &&
              Object.values(item)[0] === true
          );
          //base64
          // convertImageToBase64(image3)

          const elementData = {
            id: hasDecimalPoint(data.id) ? null : data.id,
            heading: data.name,
            detailImages: data.tagImage,
            description: data.tagDescription,
            features: detailFeatures,
            hasNewDetailImages: checkUpdate,
          };

          formData.append(
            `restaurantDetail[${index}]`,
            JSON.stringify(elementData)
          );
        });
      }
      if (values.RestaurantTags) {
        const restaurantArray = Object.values(values.RestaurantTags);
        restaurantArray.map((restTags, index) => {
          // const trimmedTag = restTags.trim();
          // if (trimmedTag !== "" ) {
          formData.append(`restaurantTags[${index}]`, restTags);
          // }
        });
      }

      if (values.dataArray.length !== Object.values(image3).length) {
        toast.dismiss();
        toast.error("Please upload all toast images ");
        return;
      }

      if (maincroppedThirdFile) {
        const detailImagesArrayFromObject = Object.values(maincroppedThirdFile);
        detailImagesArrayFromObject.map((detailImage) => {
          formData.append(`detailImages`, detailImage);
        });
      }

      if (maincroppedSecFile) {
        formData.append("menuImage", maincroppedSecFile);
      } else {
        if (maincroppedSecImage === undefined) {
          formData.append("menuImage", null);
        } else {
          formData.append("menuImage", maincroppedSecImage);
        }
      }

      // if (mainCroppedIconFile) {
      //   formData.append("restaurantIcon", mainCroppedIconFile);
      // } else {
      //   if (mainCroppedIcon !== null) {
      //     formData.append("restaurantIcon", mainCroppedIcon);
      //   } else {
      //     formData.append("restaurantIcon", null);
      //   }
      // }

      if (maincroppedFile || maincroppedImage) {
        formData.append("restaurantImage", maincroppedFile);
      } else {
        toast.dismiss();
        toast.error("Please upload Image");
        return;
      }

      if (mainCroppedBannerImageFile || maincroppedBannerImage) {
        formData.append("innerBannerImage", mainCroppedBannerImageFile);
      } else {
        toast.dismiss();
        toast.error("Please upload inner banner image");
        return;
      }
      if (id > 0) {
        editRestaurantMutation.mutate({ id, formData });
      }
    },
  });

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    getFieldProps,
  } = formik;
  // console.log("values");
  // console.log(values);
  useEffect(() => {
    setFieldValue("RestaurantName", initialValues?.RestaurantName);
    setFieldValue(
      "RestaurantDescription",
      initialValues?.RestaurantDescription
    );

    formik.resetForm({
      values: {
        ...formik.values,
        dataArray: values?.dataArray,
        RestaurantName:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_name,
        RestaurantDescription:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_description,
        RestaurantTypeId:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id,
        RestaurantTagLine:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline,
        RestaurantTagLine:
          getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline,
        RestaurantTags: getSingleRestaurant?.data?.data?.data[0].tags?.map(
          (tag) => tag.restaurant_tag
        ),
      },
    });
  }, [getSingleRestaurant.data]);

  //form update

  const editRestaurantMutation = useMutation(querydata.updateRestaurant, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);

      queryClient.resetQueries("getAllRestaurents");
      navigate("/manage-dining");
      setmainCroppedImage(null);
      setMainCroppedBannerImage(null);
      setImage(null);
      setBannerImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (getSingleRestaurant.data) {
        const dataArrays = getSingleRestaurant.data.data.data.find(
          (d) => d.detailData
        )?.detailData;
        setload(false);
        const dataArray = dataArrays?.map((restaurant) => {
          return {
            id: restaurant.restaurant_detail_id,
            name: restaurant.heading,
            tagImage: restaurant.image_path,
            tagHeading: restaurant.heading,
            tagDescription: restaurant.description,
            tagFeatures: restaurant.features.map((f) => f.features),
          };
        });

        setInitialValues((prevValues) => ({
          // ...prevValues,
          dataArray: dataArray,
        }));

        if (getSingleRestaurant?.data?.data?.data) {
          const restaurantData = getSingleRestaurant.data.data.data[0];

          setInitialValues((prevValues) => ({
            // ...prevValues,
            dataArray: dataArray,
            RestaurantName:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_name,
            RestaurantDescription:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_description,
            RestaurantTypeId:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id ||
              prevValues.RestaurantTypeId,
            RestaurantTagLine:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline ||
              prevValues.RestaurantTagLine,
            RestaurantTags:
              getSingleRestaurant?.data?.data?.data[0]?.RestaurantTypeId ||
              getSingleRestaurant?.data?.data?.data[0]?.tags?.map(
                (tag) => tag.restaurant_tag
              ) ||
              prevValues.RestaurantTags,
            RestaurantTagLine:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline ||
              prevValues.RestaurantTagLine,
          }));
        }

        formik.resetForm({
          values: {
            ...formik.values,
            dataArray: restaurandDetailDataArray,

            // RestaurantName:
            //   getSingleRestaurant?.data?.data?.data[0]?.restaurant_name,
            // RestaurantDescription:
            //   getSingleRestaurant?.data?.data?.data[0]?.restaurant_description
            //   ,
            RestaurantTagLine:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_tagline,
            RestaurantTypeId:
              getSingleRestaurant?.data?.data?.data[0]?.restaurant_type_id,
            RestaurantTags: getSingleRestaurant?.data?.data?.data[0].tags?.map(
              (tag) => tag.restaurant_tag
            ),
          },
        });
      }
    };

    fetchData();
  }, [restaurandDetailDataArray, formik.resetForm]);

  if (!getSingleRestaurant.data) {
    <div>Loading...</div>;
  }

  const handerror = () => {
    if (
      errors?.dataArray ||
      errors?.RestaurantName ||
      errors?.RestaurantDescription ||
      errors.RestaurantTypeId
    ) {
      toast.dismiss();
      toast.error("Fill all Required Fields ");
    }
  };
  console.log(mainCroppedBannerImageFile, "mainCroppedBannerImageFile");
  console.log(maincroppedBannerImage, "mainCroppedBannerImage");
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-dining">
                    Manage Dining
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Restaurant
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-item-center">
              <Link
                to="/manage-dining"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Edit Restaurant</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Edit Restaurant</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form onSubmit={handleSubmit}>
                      <Row>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Image <span className="mandatory">*</span>
                            </Form.Label>
                            {!maincroppedImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  for="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "firstImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={maincroppedImage} target="_blank">
                                    <img
                                      src={maincroppedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleRemoveImage(e, maincroppedImage)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <p className="form-error text-danger">
                              {maincroppedImage ? "" : "Please enter image "}
                            </p> */}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Inner Banner Image{" "}
                              <span className="mandatory">*</span>
                            </Form.Label>
                            {!maincroppedBannerImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  for="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "BannerImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedBannerImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a
                                    href={maincroppedBannerImage}
                                    target="_blank"
                                  >
                                    <img
                                      src={maincroppedBannerImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleBannerImage(e, image)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <p className="form-error text-danger">
                              {maincroppedImage ? "" : "Please enter image "}
                            </p> */}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Restaurant Name{" "}
                              <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-3 shadow-none px-4"
                              value={values.RestaurantName}
                              onChange={
                                (e) =>
                                  // test1(e)
                                  setFieldValue(
                                    "RestaurantName",
                                    e.target.value
                                  )

                                // setFieldValue("RestaurantName", e.target.value)
                              }
                              name="RestaurantName"
                              maxLength={20}
                            />
                            {errors.RestaurantName && touched.RestaurantName ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantName}
                              </p>
                            ) : error.RestaurantName ? (
                              <p className="form-error text-danger">
                                {error.RestaurantName}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Restaurant Type{" "}
                              {/* <span className="mandatory">*</span> */}
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="py-3 shadow-none px-4"
                              onChange={(e) =>
                                setFieldValue(
                                  "RestaurantTypeId",
                                  e.target.value
                                )
                              }
                              value={values.RestaurantTypeId}
                              name="RestaurantTypeId"
                            >
                              <option value="0">---Select---</option>
                              {AllRestaurantTypeId.data &&
                                AllRestaurantTypeId.data.data.map((option) => (
                                  <option
                                    key={option.restaurant_type_id}
                                    value={option.restaurant_type_id}
                                  >
                                    {option.restaurant_type}
                                  </option>
                                ))}
                            </Form.Select>
                            {errors.RestaurantTypeId &&
                            touched.RestaurantTypeId ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTypeId}
                              </p>
                            ) : error.RestaurantTypeId ? (
                              <p className="form-error text-danger">
                                {error.RestaurantTypeId}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Tag Line <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              value={values.RestaurantTagLine}
                              onChange={(e) =>
                                // test2(e)
                                setFieldValue(
                                  "RestaurantTagLine",
                                  e.target.value
                                )
                              }
                              name="RestaurantTagLine"
                            />
                            {errors.RestaurantTagLine &&
                            touched.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTagLine}
                              </p>
                            ) : error.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {error.RestaurantTagLine}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Description <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              value={values.RestaurantDescription}
                              onChange={(e) =>
                                // test2(e)
                                setFieldValue(
                                  "RestaurantDescription",
                                  e.target.value
                                )
                              }
                              name="RestaurantDescription"
                            />
                            {errors.RestaurantDescription &&
                            touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantDescription}
                              </p>
                            ) : error.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {error.RestaurantDescription}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        {/* <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Restaurant Icon</Form.Label>
                            {!mainCroppedIcon && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "restaurantIcon")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {mainCroppedIcon && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={mainCroppedIcon} target="_blank">
                                    <img
                                      src={mainCroppedIcon}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleRemoveIcon(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col> */}
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Show Menu/Activities</Form.Label>
                            {!maincroppedSecImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  for="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "secondImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose menu from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedSecImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={
                                    maincroppedSecImage.endsWith(".pdf")
                                      ? {
                                          width: "68%",
                                          height: "68%",
                                        }
                                      : { width: "100%", height: "100%" }
                                  }
                                >
                                  <a
                                    href={
                                      maincroppedSecImage.endsWith(".pdf")
                                        ? maincroppedSecImage
                                        : maincroppedSecImage
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      // src={maincroppedSecImage}
                                      src={
                                        maincroppedSecImage.endsWith(".pdf")
                                          ? pdfImg
                                          : maincroppedSecImage
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleSecRemoveImage(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Restaurant Tags</Form.Label>

                            <RestaurentTags
                              setFieldValue={setFieldValue}
                              name={getFieldProps("RestaurantTags").name}
                              value={getFieldProps(`RestaurantTags`).value}
                              initialValues={initialValues}
                              maxLength={20}
                              values={values}
                              formik={formik}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </form>
                    <RestaurantDetailEdit
                      setsectionImage={setsectionImage}
                      setImage3={setImage3}
                      formik={formik}
                      handleThirdRemoveImage={handleThirdRemoveImage}
                      handleRemoveImage={handleRemoveImage}
                      showCropMediaModal={showCropMediaModal}
                      image={image}
                      image3={image3}
                      maincroppedThirdImage={maincroppedThirdImage}
                      setRestaurandDetailDataArray={
                        setRestaurandDetailDataArray
                      }
                      setInitialValues={setInitialValues}
                      restaurandDetailDataArray={restaurandDetailDataArray}
                      values={values}
                      initialValues={initialValues}
                      handleChange={handleChange}
                      setValues={setValues}
                      getFieldProps={getFieldProps}
                      setFieldValue={setFieldValue}
                    />
                    <div className="save-btn text-end mt-4">
                      {/* <input
                        type="submit"
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        onClick={() => {
                          handleSubmit();
                          handerror();
                        }}
                        value={isLoading ? <div class="spinner-border text-primary" role="status"></div> : "Update"}
                      /> */}
                      <Button
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        onClick={() => {
                          handleSubmit();
                          handerror();
                        }}
                      >
                        {isLoading ? (
                          <div
                            class="spinner-border text-primary"
                            role="status"
                          ></div>
                        ) : (
                          "Update"
                        )}
                      </Button>

                      {/* </Button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumView
              formikEdit={formik}
              image3={image3}
              dataCommingFrom="edit"
              imgArray={imgArray}
              currentFileId={currentFileId}
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedSecImage={setmainCroppedSecImage}
              setmainCroppedSecFile={setmainCroppedSecFile}
              setmainCroppedThirdFile={setmainCroppedThirdFile}
              setmainCroppedThirdImage={setImage3}
              setmainCroppedImage={setmainCroppedImage}
              maincroppedImage={maincroppedImage}
              setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
              setMainCroppedBannerImage={setMainCroppedBannerImage}
              checkValForBannerImage={checkValForBannerImage}
              checkValForSingleImg={checkValForSingleImg}
              checkValForSingleImg3={checkValForSingleImg3}
              setMainCroppedIcon={setMainCroppedIcon}
              setmainCroppedIconFile={setmainCroppedIconFile}
              checkValForIcon={checkValForIcon}
              checkMultipleImageSection={checkMultipleImageSection}
              setmainCroppedFile={setmainCroppedFile}
              // setting_value={
              //   getAspectRatio &&
              //   getAspectRatio.data &&
              //   getAspectRatio.data.data &&
              //   getAspectRatio.data.data.setting_value
              // }
              setting_value={settingValue}
              maincroppedThirdFile={maincroppedThirdFile}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
