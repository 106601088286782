import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { Inner } from "./Inner";

export const Column = ({
  tasks,
  handleShowModal,
  handleShowEditSubSectionModal,
}) => {
  return (
    <ul className="nav flex-column" id="myTab" role="tablist">
      <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
        {tasks?.map((subsection) => (
          <Inner
            key={subsection.id}
            id={subsection.id}
            title={"subsection.title"}
            subsection={subsection}
            handleShowEditSubSectionModal={handleShowEditSubSectionModal}
            handleShowModal={handleShowModal}
            eleemnt
            elementsCount={tasks.length}
          />
        ))}
      </SortableContext>
    </ul>
    // <div className="column">
    //   <SortableContext items={tasks} strategy={verticalListSortingStrategy}>
    //     {tasks?.map((subsection) => (
    //       <Inner
    //         //   key={subsection.page_sub_section_id}
    //         //   id={subsection.page_sub_section_id}
    //         key={subsection.id}
    //         id={subsection.id}
    //         subsection={subsection}
    //         title={subsection}
    //         handleShowEditSubSectionModal={handleShowEditSubSectionModal}
    //         handleShowModal={handleShowModal}
    //       />
    //     ))}
    //   </SortableContext>
    // </div>
  );
};
