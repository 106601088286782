import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

const SideBarItem = ({ data, isActive, onSideBarItemClick }) => {
  const [isSideItemActive, setIsSideBarActive] = useState(false);
  const navigate = useNavigate();
  // if(!data.subItems){
  //   isActive=true;
  // }
  const handleSideBarItemClick = () => {
    const headerElement = document.getElementById('header');
    const sidebarElement = document.getElementById('sidebar-wrapper');
    const sideItemUlElement = document.getElementById('side-item-ul');
    if (isActive) {
      headerElement.classList.remove('sidebar-default');
      sidebarElement.classList.remove('sidebar-default');

    }
    else {
      headerElement.classList.add('sidebar-default');
      sidebarElement.classList.add('sidebar-default');

    }
    onSideBarItemClick();
    navigate(data.navigation_url);
  }



  return (
    <li className="sidebar-list">
      <button
        // onClick={handleSideBarItemClick}
        onClick={handleSideBarItemClick}
        className={`btn sidebar-link sidebar-title w-100 px-2 d-flex align-items-center  text-decoration-none  ${isActive ? "active" : ""}`}
      >
        <span className="menu-icon d-flex justify-content-center">
          <i className={data.navigation_icon}></i>
        </span>
        <span className={`menu-name d-flex text-white menu-text text-uppercase fw-semibold `}>
          {data.navigation_item}
          {/* {data.subItems &&  <FontAwesomeIcon className='text-white' icon={faAngleDown} />} */}
          {data.subItems &&
            (isActive ? (
              <FontAwesomeIcon className='text-white' icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon className='text-white' icon={faAngleDown} />
            ))}
        </span>
      </button>
      {data.subItems && <ul id="side-item-ul" className={`sidebar-submenu custom-scrollbar list-unstyled ${isActive ? "d-block" : "d-none"}`} >
        <li className="sidebar-head">{data.navigation_item}</li>
        {data.subItems && data.subItems.map(subMenuData => (
          <li className="main-submenu" key={subMenuData.navigation_item_id}>
            <Link className="d-flex sidebar-menu" to={subMenuData.navigation_url}>
              <span className="d-none">
                <i className={`${subMenuData.navigation_icon}`}></i>
              </span>
              <span className="submenu-name">{subMenuData.navigation_item}</span>
            </Link>
          </li>
        ))}

      </ul>}

    </li>
  );
};

export default SideBarItem;
