import { useParams, useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentDate } from "../../helper/CommonHelper";
import { BsFillInfoCircleFill } from "react-icons/bs";

// crop image start
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelection";
// crop image end
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
export const offerSchema = Yup.object({
  offerName: Yup.string().required("Please enter offer name"),

  discountText: Yup.string().required("Please enter discount text"),
  resortId: Yup.number().required("Please select resort"),
  startDate: Yup.string().required("Please select start date"),

  description: Yup.string().required("Please enter description"),
  website: Yup.string().required("Please enter URL"),
  termsconditions: Yup.string().required("Please enter terms & conditions"),
  highlights: Yup.string().required("Please enter highlights"),
  // offerImage: Yup.mixed().required("Please upload an image"),
});
const EditOffers = () => {
  const navigate = useNavigate();
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark">{children}</a>
    </OverlayTrigger>
  );
  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [err, setErr] = useState(false);
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = () => {
    setCropMediaModal(true);
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "offer_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("offer_aspect_ratio")
  );
  const handleRemoveImage = () => {
    // Handle removing the image
    setErr(true)
    setImage(null);
    setmainCroppedImage(null);
  };
  // crop code end
  useEffect(() => {
    if (maincroppedFile) {
      setErr(false);
    }
  }, [maincroppedFile]);
  const [offerfile, setofferfile] = useState("");
  const [image, setImage] = useState(null);
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useParams();

  const getsingleOffers = useQuery(
    ["Resorts", id], // Using an array as the query key with offerId
    () => querydata.getSingleOffer(id)
  );

  const initialValues = {
    offerName:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.offer_name
        ? getsingleOffers.data.data.offer_name
        : "",
    discount:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.discount_percent
        ? getsingleOffers.data.data.discount_percent
        : "",
    discountText:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.discount_text
        ? getsingleOffers.data.data.discount_text
        : "",
    resortId:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.brand_resort_id
        ? getsingleOffers.data.data.brand_resort_id
        : "",
    endDate:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.offer_end_date
        ? getsingleOffers.data.data.offer_end_date.split("T")[0]
        : "",
    startDate:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.offer_start_date
        ? getsingleOffers.data.data.offer_start_date.split("T")[0]
        : "",
    featuredHR:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.is_featured_to_brand
        ? getsingleOffers.data.data.is_featured_to_brand
          ? true
          : false
        : "",
    featuredKF:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.is_featured_to_resort
        ? getsingleOffers.data.data.is_featured_to_resort === 1
          ? true
          : false
        : "",
    description:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.description
        ? getsingleOffers.data.data.description
        : "",
    website:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.website_url
        ? getsingleOffers.data.data.website_url
        : "",
    termsconditions:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.terms_conditions
        ? getsingleOffers.data.data.terms_conditions
        : "",
    highlights:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.highlights
        ? getsingleOffers.data.data.highlights
        : "",
    statusId:
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.statusId
        ? getsingleOffers.data.data.statusId === 1
          ? 1
          : 0
        : 0,
    offerImage: getsingleOffers?.data?.data?.offer_image,
  };

  useEffect(() => {
    setValues({ ...initialValues });
    const originalUrl =
      getsingleOffers &&
      getsingleOffers.data &&
      getsingleOffers.data.data &&
      getsingleOffers.data.data.offer_image;
    const modifiedUrl =
      originalUrl &&
      originalUrl.replace("http://localhost:8000", "http://172.16.1.123:8000");

    setImage(modifiedUrl);
  }, [getsingleOffers?.data]);

  const handlecheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFieldValue("featuredHR", checked);
  };
  const handlecheckboxChangeR = (event) => {
    const { name, checked } = event.target;
    setFieldValue("featuredKF", checked);
  };
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    setErrors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: offerSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("offerName", values.offerName);
      formData.append("brandResortId", values.resortId);
      formData.append("discountPercent", values.discount);
      formData.append("discountText", values.discountText);
      formData.append("isFeaturedResort", values.featuredKF);
      formData.append("isFeaturedBrand", values.featuredHR);
      formData.append("websiteId", "1");
      formData.append("description", values.description);
      formData.append("offerStartDate", values.startDate);
      formData.append("offerEndDate", values.endDate);
      formData.append("websiteURL", values.website);
      formData.append("termsCondition", values.termsconditions);
      formData.append("highlights", values.highlights);
      formData.append("is_active", values.statusId);
      // formData.append("offerImage", maincroppedFile);

      // if (maincroppedFile) formData.append("offerImage", maincroppedFile);
      // else {
      //   error.file = "Please Upload Image";
      //   console.log(error.file);
      //   return;
      // }
      if (maincroppedFile) {
        setErr(false)
        formData.append("offerImage", maincroppedFile);
      } else {
        setErr(true)
        // If image is not provided, set error for offerImage field
        setErrors({ offerImage: "Please upload an image" });
        return;
      }
      addnewprojectMutation.mutate({ id, formData });
    },
  });

  const addnewprojectMutation = useMutation(querydata.updateOffer, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-offers");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const getAllResorts = useQuery("Resorts", querydata.getAllResorts);

  const handleofferfilechange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedFormats = ["pdf", "jpg", "jpeg", "png"]; // Allowed file formats
    const maxFileSizeInBytes = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
      const fileSize = selectedFile.size;
      const reader = new FileReader();

      reader.onloadend = () => {
        setImage(reader.result); // Set the image preview URL
      };
      // Check if the file format is allowed
      if (!allowedFormats.includes(fileExtension)) {
        setofferfile(null);
        // document.getElementById("filetextid").value = "";
        error.file =
          "Invalid file format. Allowed formats: " + allowedFormats.join(", ");
      } else if (fileSize > maxFileSizeInBytes) {
        // Check if the file size exceeds the limit
        setofferfile(null);
        //document.getElementById("filetextid").value = "";
        error.file = "File size exceeds the limit of 2 MB.";
      } else {
        // The file format and size are allowed, so set it in the state
        setofferfile(selectedFile);
        // document.getElementById("filetextid").value = selectedFile.name;
        error.file = "";
        reader.readAsDataURL(selectedFile);
      }
    } else {
      setofferfile(null);
      //document.getElementById("filetextid").value = "";
      error.file = "Please Upload offer";
    }
  };

  if (!getAllResorts.data) {
    return <div>Loading....</div>;
  }
  if (!getsingleOffers?.data) {
    return <div>Loading....</div>;
  }


  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-offers">
                    Manage Offers
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Offer
                </li>
              </ol>
            </nav>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Edit Offer </h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <div className="row">
                      <div className="col-xl-9 col-lg-7">
                        <form action="#" method="get">
                          <div className="row">
                            <div className="col-12">
                              <div className="mb-4">
                                <label className="form-label">
                                  {" "}
                                  Image <span className="mandatory">*</span>
                                </label>
                                {!maincroppedImage && !image && (
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={showCropMediaModal}
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Upload image from gallery
                                      </button>
                                    </div>
                                  </label>
                                )}

                                {maincroppedImage && (
                                  <div
                                    className="uploaded-img-view"
                                    style={{
                                      width: "200px",
                                      position: "relative",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div>
                                      <a
                                        href={maincroppedImage}
                                        target="_blank"
                                      >
                                        <img
                                          src={maincroppedImage}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      >
                                        <button
                                          className="remove-image-button"
                                          onClick={handleRemoveImage}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {!maincroppedImage && image && (
                                  <div
                                    className="uploaded-img-view"
                                    style={{
                                      width: "200px",
                                      position: "relative",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <div>
                                      <a href={image} target="_blank">
                                        <img
                                          src={image}
                                          style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      >
                                        <button
                                          className="remove-image-button"
                                          onClick={handleRemoveImage}
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                 {err ? (
              // errors.offerImage > 0 ? (
              //   ""
              // ) : (
                <p className="form-error text-danger">Please select a file </p>
              // )
            ) : (
              ""
            )}
                                {/* {errors.offerImage && touched.offerImage ? (
                                  <p className="form-error text-danger">
                                    {errors.offerImage}
                                  </p>
                                ) : errors.offerImage ? (
                                  <p className="form-error text-danger">
                                    {errors.offerImage}
                                  </p>
                                ) : null} */}
                                {/* {errors.offerfile && touched.offerfile ? (
                                  <p className="form-error text-danger">
                                    {errors.offerfile}
                                  </p>
                                ) : error.file ? (
                                  <p className="form-error text-danger">
                                    {error.file}
                                  </p>
                                ) : !errors.offerfile &&
                                  error.file &&
                                  touched.offerfile ? (
                                  <p className="form-error text-danger">
                                    Please select a file
                                  </p>
                                ) : null} */}
                              </div>
                            </div>
                            {/* <!-- /col-12 --> */}
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="OfferName"
                                >
                                  Offer Name{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  name="offerName"
                                  value={values.offerName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  maxLength={100}
                                  type="text"
                                  id="OfferName"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.offerName && touched.offerName ? (
                                <p className="form-error text-danger">
                                  {errors.offerName}
                                </p>
                              ) : error.offerName ? (
                                <p className="form-error text-danger">
                                  {error.offerName}
                                </p>
                              ) : null}
                            </div>
                            {/* <!-- /col-12 --> */}
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="OfferType"
                                >
                                  Resort <span className="mandatory">*</span>
                                </label>
                                <select
                                  name="resortId"
                                  id="OfferType"
                                  className="form-control form-select py-3 shadow-none px-4"
                                  onChange={handleChange}
                                  value={values.resortId}
                                >
                                  <option value="">---Select---</option>
                                  {getAllResorts.data.data.map((option) => (
                                    <option
                                      key={option.brand_resort_id}
                                      value={option.brand_resort_id}
                                    >
                                      {option.resort_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {errors.resortId && touched.resortId ? (
                                <p className="form-error text-danger">
                                  {errors.resortId}
                                </p>
                              ) : error.resortId ? (
                                <p className="form-error text-danger">
                                  {error.resortId}
                                </p>
                              ) : !errors.resortId &&
                                error.resortId &&
                                touched.resortId ? (
                                <p className="form-error text-danger">
                                  Please select a resort
                                </p>
                              ) : null}
                            </div>
                            {/* <!-- /col-12 --> */}
                            <div className="col-lg-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="Discount"
                                >
                                  Discount(%)
                                </label>
                                <input
                                  name="discount"
                                  value={values.discount}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  id="Discount"
                                  className="form-control py-3 shadow-none px-4"
                                  min={0}
                                  step={1}
                                  maxLength={3}
                                  pattern="[0-9]"
                                  onKeyPress={(e) => {
                                    // Allow only numeric characters and certain special keys (e.g., backspace, arrow keys)
                                    const isValidInput = /[0-9\b]/.test(e.key);
                                    if (!isValidInput) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-2">
                                <label
                                  className="form-label"
                                  htmlFor="Discount"
                                >
                                  Discount Text{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  name="discountText"
                                  value={values.discountText}
                                  maxLength={50}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  id="DiscountText"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.discountText && touched.discountText ? (
                                <p className="form-error text-danger">
                                  {errors.discountText}
                                </p>
                              ) : error.discountText ? (
                                <p className="form-error text-danger">
                                  {error.discountText}
                                </p>
                              ) : !errors.discountText &&
                                error.discountText &&
                                touched.discountText ? (
                                <p className="form-error text-danger">
                                  Please enter discount text
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="Description"
                                >
                                  Description{" "}
                                  <span className="mandatory">*</span>
                                </label>

                                <textarea
                                  name="description"
                                  value={values.description}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  id="Description"
                                  className="form-control py-3 shadow-none px-4"
                                  cols="30"
                                  rows="5"
                                ></textarea>
                              </div>
                              {errors.description && touched.description ? (
                                <p className="form-error text-danger">
                                  {errors.description}
                                </p>
                              ) : error.description ? (
                                <p className="form-error text-danger">
                                  {error.description}
                                </p>
                              ) : !errors.description &&
                                error.description &&
                                touched.description ? (
                                <p className="form-error text-danger">
                                  Please enter offer description
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label className="form-label" htmlFor="Website">
                                  Book Now URL{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  name="website"
                                  value={values.website}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="url"
                                  id="Website"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.website && touched.website ? (
                                <p className="form-error text-danger">
                                  {errors.website}
                                </p>
                              ) : error.website ? (
                                <p className="form-error text-danger">
                                  {error.website}
                                </p>
                              ) : !errors.website &&
                                error.website &&
                                touched.website ? (
                                <p className="form-error text-danger">
                                  Please enter website
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="TermsConditions"
                                >
                                  Terms & Conditions{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <span className="toolIcon ms-2">
                                  <ToolLink
                                    title="Press 'Enter' to add a new bullet point"
                                    id="t-1"
                                  >
                                    <BsFillInfoCircleFill />
                                  </ToolLink>{" "}
                                </span>
                                <textarea
                                  name="termsconditions"
                                  value={values.termsconditions}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  rows="3"
                                  id="TermsConditions"
                                  className="form-control py-3 shadow-none px-4"
                                ></textarea>
                              </div>
                              {errors.termsconditions &&
                              touched.termsconditions ? (
                                <p className="form-error text-danger">
                                  {errors.termsconditions}
                                </p>
                              ) : error.termsconditions ? (
                                <p className="form-error text-danger">
                                  {error.termsconditions}
                                </p>
                              ) : !errors.termsconditions &&
                                error.termsconditions &&
                                touched.termsconditions ? (
                                <p className="form-error text-danger">
                                  Please enter termsconditions
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-12">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="Highlights"
                                >
                                  Highlights{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <span className="toolIcon ms-2">
                                  <ToolLink
                                    title="Press 'Enter' to add a new bullet point"
                                    id="t-1"
                                  >
                                    <BsFillInfoCircleFill />
                                  </ToolLink>{" "}
                                </span>
                                <textarea
                                  name="highlights"
                                  value={values.highlights}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  type="text"
                                  rows="3"
                                  id="Highlights"
                                  className="form-control py-3 shadow-none px-4"
                                ></textarea>
                              </div>
                              {errors.highlights && touched.highlights ? (
                                <p className="form-error text-danger">
                                  {errors.highlights}
                                </p>
                              ) : error.highlights ? (
                                <p className="form-error text-danger">
                                  {error.highlights}
                                </p>
                              ) : !errors.highlights &&
                                error.highlights &&
                                touched.highlights ? (
                                <p className="form-error text-danger">
                                  Please enter highlights
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label
                                  className="form-label"
                                  htmlFor="StartDate"
                                >
                                  Offer Start Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  type="date"
                                  selected={values.startDate}
                                  name="startDate"
                                  value={values.startDate}
                                  onChange={handleChange}
                                  id="StartDate"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.startDate && touched.startDate ? (
                                <p className="form-error text-danger">
                                  {errors.startDate}
                                </p>
                              ) : error.startDate ? (
                                <p className="form-error text-danger">
                                  {error.startDate}
                                </p>
                              ) : !errors.startDate &&
                                error.startDate &&
                                touched.startDate ? (
                                <p className="form-error text-danger">
                                  Please enter startDate
                                </p>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <div className="mb-4">
                                <label className="form-label" htmlFor="EndDate">
                                  Offer End Date{" "}
                                  <span className="mandatory">*</span>
                                </label>
                                <input
                                  type="date"
                                  selected={values.endDate}
                                  name="endDate"
                                  value={values.endDate}
                                  onChange={handleChange}
                                  min={values.startDate || getCurrentDate()}
                                  id="EndDate"
                                  className="form-control py-3 shadow-none px-4"
                                />
                              </div>
                              {errors.endDate && touched.endDate ? (
                                <p className="form-error text-danger">
                                  {errors.endDate}
                                </p>
                              ) : error.endDate ? (
                                <p className="form-error text-danger">
                                  {error.endDate}
                                </p>
                              ) : !errors.endDate &&
                                error.endDate &&
                                touched.endDate ? (
                                <p className="form-error text-danger">
                                  Please enter endDate
                                </p>
                              ) : null}
                            </div>

                            <div className="col-12 text-end mt-lg-4 mt-0">
                              <Button
                                type="submit"
                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                                onClick={handleSubmit}
                              >
                                {isLoading ? (
                                  <div
                                    class="spinner-border text-primary"
                                    role="status"
                                  ></div>
                                ) : (
                                  "SAVE"
                                )}
                              </Button>
                              {/* <input
                                onClick={handleSubmit}
                                type="submit"
                                value="Save"
                                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                              /> */}
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-xl-3 col-lg-5 mt-lg-0 mt-4">
                        <div className="card mb-4">
                          <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                            <h5 className="m-0 fw-medium">Details</h5>
                          </div>
                          <div className="card-body">
                            <div className="offer-info bg-white">
                              <ul className="list-unstyled m-0 p-0">
                                <li>
                                  <strong>Created</strong>
                                  <span>
                                    {getsingleOffers &&
                                    getsingleOffers.data &&
                                    getsingleOffers.data.data &&
                                    getsingleOffers.data.data.created_on
                                      ? getsingleOffers.data.data.created_on.split(
                                          "T"
                                        )[0]
                                      : ""}
                                  </span>
                                </li>
                                <li>
                                  <strong>By</strong>
                                  <span>
                                    {getsingleOffers &&
                                      getsingleOffers.data &&
                                      getsingleOffers.data.data &&
                                      getsingleOffers.data.data.created_by}
                                  </span>
                                </li>
                                <li>
                                  <strong>Last Update</strong>
                                  <span>
                                    {getsingleOffers &&
                                      getsingleOffers.data &&
                                      getsingleOffers.data.data &&
                                      getsingleOffers.data.data.updated_on &&
                                      getsingleOffers.data.data.updated_on.split(
                                        "T"
                                      )[0]}
                                  </span>
                                </li>
                                <li>
                                  <strong>By</strong>
                                  <span>
                                    {getsingleOffers &&
                                      getsingleOffers.data &&
                                      getsingleOffers.data.data &&
                                      getsingleOffers.data.data.updated_by}
                                  </span>
                                </li>
                              </ul>
                            </div>

                            <div className="Locales border-top mt-4 pt-3">
                              <h6 className="fw-bold">Featured To</h6>
                              <label
                                htmlFor="featuredHR"
                                className="position-relative d-flex"
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    values.featuredHR === true ||
                                    values.featuredHR === false
                                      ? values.featuredHR
                                      : values.featuredHR === 1
                                      ? true
                                      : false
                                  }
                                  onChange={handlecheckboxChange}
                                  name="featuredHR"
                                  id="featuredHR"
                                />
                                <span className="ms-2 text-muted">
                                  Group Website
                                </span>
                              </label>
                              <label
                                htmlFor="featuredKF"
                                className="position-relative d-flex"
                                data-id={values.featuredKF}
                              >
                                <input
                                  type="checkbox"
                                  checked={
                                    values.featuredKF === true ||
                                    values.featuredKF === false
                                      ? values.featuredKF
                                      : values.featuredKF === 1
                                      ? true
                                      : false
                                  }
                                  onChange={handlecheckboxChangeR}
                                  name="featuredKF"
                                  id="featuredKF"
                                />
                                <span className="ms-2 text-muted">
                                  Resort Website
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewSelection
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedImage={setmainCroppedImage}
              setmainCroppedFile={setmainCroppedFile}
              setting_value={
                getAspectRatio &&
                getAspectRatio.data &&
                getAspectRatio.data.data &&
                getAspectRatio.data.data.setting_value
              }
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditOffers;
