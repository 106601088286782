import React, { useState } from "react";

import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";
import OfferCard from "./OfferCard";

const Table1 = ({ columns, data, pageNumber = 0, resortsData }) => {
  const [isListView, setIsListView] = useState(true);
  const [filteredData, setFilteredData] = useState();
  const initialPageSize = 20;
  const toggleView = () => {
    setIsListView(true);
  };
  const toggleGridView = () => {
    setIsListView(false);
  };

  //const data1 = filteredData ? filteredData : data;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    state: { filters },
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initialPageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(pageNumber);
  // Handle pagination controls
  const handleGoToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    gotoPage(pageIndex);
  };

  const handleNextPage = () => {
    setCurrentPageIndex(pageIndex + 1);
    nextPage();
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(pageIndex - 1);
    previousPage();
  };

  const setdatafilter = (e) => {
    setFilter("resort_name", e);
    var i = 1;
  };

  return (
    <div className="card mt-lg-5 mt-4">
      <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
        <h5 className="m-0 fw-medium">View Offers</h5>
        <div className="right-table-actions d-flex">
          <div className="filter-buttons d-flex align-items-center">
            <form action="#" method="get">
              <div className="position-relative">
                <input
                  value={state.globalFilter || ""}
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  type="text"
                  className="form-control rounded-1"
                  placeholder="Type to Search..."
                />
              </div>
            </form>
            <div className="atmosphere-hr mx-3">
              {/* <select className="form-control rounded-1 form-select" name="" id="" onChange={(e)=>setFilter("resort_name",e.target.value)}> */}
              <select
                className="form-control rounded-1 form-select"
                name=""
                id=""
                onChange={(e) => setdatafilter(e.target.value)}
              >
                <option value="">All Resorts</option>
                {resortsData &&
                  resortsData.map((resort) => (
                    <option
                      key={resort.brand_resort_id}
                      value={resort.resort_name}
                    >
                      {resort.resort_name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div
          id="list-grid-view"
          className={
            isListView
              ? "tabledata list-view-filter"
              : "tabledata grid-view-filter"
          }
        >
          {isListView ? (
            <div className="list-offers">
              <div className="table-responsive">
                <table {...getTableProps()} className="table">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, index) => (
                            <td {...cell.getCellProps()}>
                              {index === 0
                                ? row.index + 1
                                : cell.render("Cell")}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div>
                <label
                  className="d-flex align-items-center mt-2"
                  style={{ maxWidth: "190px" }}
                >
                  Show{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setCurrentPageIndex(0); // Reset to the first page when changing rows per page
                      setPageSize(Number(e.target.value));
                    }}
                    className="form-select ms-3 me-2"
                  >
                    {[20, 40, 60, 80, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>{" "}
                  entries
                </label>
              </div>
              <div className="atmosphere-pagination">
                <div className="d-flex align-items-center justify-content-between mt-3">
                  <div>
                    Showing {pageIndex * pageSize + 1} to{" "}
                    {Math.min((pageIndex + 1) * pageSize, rows.length)} of{" "}
                    {rows.length} entries
                  </div>
                  <div className="atmosphere-pagination">
                    <ul className="pagination m-0 d-flex justify-content-end">
                      <li className="page-item">
                        <button
                          onClick={() => handlePreviousPage()}
                          disabled={!canPreviousPage}
                          className="page-link d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-angle-left"></i>
                          {/* <i className="fa-solid fa-angle-left"></i> */}
                        </button>
                      </li>
                      {Array.from({ length: pageCount }, (_, index) => (
                        <li className="page-item" key={index}>
                          <button
                            className={
                              pageIndex === index
                                ? "page-link d-flex align-items-center justify-content-center active"
                                : ""
                            }
                            key={index}
                            onClick={() => handleGoToPage(index)}
                            disabled={pageIndex === index}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}

                      <li className="page-item">
                        <button
                          className="page-link d-flex align-items-center justify-content-center"
                          onClick={() => handleNextPage()}
                          disabled={!canNextPage}
                        >
                          <i className="fa fa-angle-right"></i>

                          {/* <i className="fa-solid fa-angle-right"></i> */}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid-offers">
              <ul className="list-unstyled d-flex flex-wrap align-items-center mt-0 mb-0 p-0">
                {data && data.map((data) => <OfferCard data={data} />)}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table1;
