import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import { toast } from "react-toastify";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import DeleteModal from "./DeleteModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import backarrow from "../../assets/images/back-arrow.svg";

// crop image start
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelection";
// crop image end

export const pressReleaseSchema = Yup.object({
  title: Yup.string().trim().required("Please enter title "),
  pressReleaseDate: Yup.string().required("Please select press release date"),
  pressReleaseTime: Yup.string().required("Please select press release time"),
  description: Yup.string().trim().required("Please enter description"),

  // resortId: Yup.number().required("Please Select Resort"),

});

const CreatePressRelease = () => {
  const navigate = useNavigate();
  const [offerfile, setofferfile] = useState("");
  const [image, setImage] = useState(null);
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [description, setdescription] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = () => {
    setCropMediaModal(true);
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "press_release_aspect_ratio"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("press_release_aspect_ratio")
  );
  // crop code end

  const handleShowModal = (rowData, title) => {
    setSelectedRowData(rowData);
    setSelectedRowNameData(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const { id } = useParams();

  const getsinglePressRelease = useQuery(
    ["SinglePressRelease", id], // Using an array as the query key with offerId
    () => querydata.getSinglePressRelease(id)
  );



  const initialValues = {
    title:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.title
        ? getsinglePressRelease.data.data.title
        : "",
    pressReleaseDate:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.date
        ? getsinglePressRelease.data.data.date.split("T")[0]
        : "",
    pressReleaseTime:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.time
        ? getsinglePressRelease.data.data.time
        : "",
    description:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.description
        ? getsinglePressRelease.data.data.description
        : "",
    tags:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.tags
        ? getsinglePressRelease.data.data.tags
        : "",
    slug:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.slug
        ? getsinglePressRelease.data.data.slug
        : "",
    is_published:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.is_published
        ? getsinglePressRelease.data.data.is_published
        : "",
    resortId:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.brand_resort_ids
        ? getsinglePressRelease.data.data.brand_resort_ids
        : "",
    publish_date:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.published_date !== null
        ? getsinglePressRelease.data.data.published_date.split("T")[0]
        : "",
    publish_time:
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.published_date !== null
        ? getsinglePressRelease.data.data.published_date
            .split("T")[1]
            .split(".")[0]
            .slice(0, 5)
        : "",
  };
  useEffect(() => {
    setValues({ ...initialValues });
    const originalUrl =
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.image_path;
    const modifiedUrl =
      originalUrl &&
      originalUrl.replace("http://localhost:8000", "http://172.16.1.123:8000");

    setImage(modifiedUrl);
    const descriptionValue =
      getsinglePressRelease &&
      getsinglePressRelease.data &&
      getsinglePressRelease.data.data &&
      getsinglePressRelease.data.data.description;

    setFieldValue("description", descriptionValue);
    setFieldValue("description", descriptionValue);
  }, [getsinglePressRelease.data]);

  const getAllResorts = useQuery("Resorts", querydata.getAllResorts);
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
    setFieldError ,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: pressReleaseSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("date", values.pressReleaseDate);
      formData.append("time", values.pressReleaseTime);
      formData.append("description", values.description);
      formData.append("tags", values.tags);
      formData.append("slug", values.slug);
      formData.append("is_published", values.is_published);
      formData.append("brand_resort_id", values.resortId);
      formData.append("publish_date", values.publish_date);
      formData.append("publish_time", values.publish_time);
     
        formData.append("pressImage", maincroppedFile);
        // If maincroppedFile is null, set an error message for the pressImage field
 
     
      
        addMutation.mutate({ id, formData });
      //action.resetForm();
    },
  });

  //form post
  const addMutation = useMutation(querydata.updatePressRelease, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-press-releases");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  
  const handleRemoveImage = () => {
    // Handle removing the image
    setImage(null);
    setmainCroppedImage(null);
    setmainCroppedFile(null)
  };

  ///editor start
  const fullEditorRef = useRef(null);
  
  useEffect(() => {
    // Assuming fullEditorRef.current is the ReactQuill instance
    const quill = fullEditorRef.current?.getEditor();

    if (quill) {
      // Listen for text-change event in the Quill editor
      quill.on("text-change", () => {
        const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
        setFieldValue("description", descriptionValue); // Update the Formik state
      });
    }
  }, [fullEditorRef.current]);

  

  //   const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'indent', 'link'];
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  ///editor end
  if (!getsinglePressRelease.data) {
    return <div>Loading....</div>;
  }
  const preSelectedResortIds = values.resortId;
  // const preSelectedResortIds = preSelectedResortIdsString ? preSelectedResortIdsString.split(',').map(Number) : [];

  const handleChangeResort = (selected) => {
    setSelectedOptions(selected);
    const resortIdValue =
      selected.length > 0 ? selected.map((option) => option.value) : null;

    // Update state or form values
    setValues({
      ...values,
      resortId: resortIdValue,
    });
  };


  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to="/manage-press-releases"
                  >
                    manage-press-releases
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Edit Press Release
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-items-center">
              <Link
                to="/manage-press-releases"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Press Release</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-xl-9 col-lg-7">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Edit Press Release</h5>
                  <div className="right-actions d-none">
                    <a
                      href="#"
                      className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2"
                    >
                      Save Draft
                    </a>
                    <a
                      href="#"
                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                    >
                      Preview
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form action="#" method="get">
                      <div className="row">
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">Title  <span className="mandatory">*</span> 
                          </label>

                         
                          <input
                          
                            name="title"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            maxLength={100}
                            id="Name"
                            className="form-control py-3 shadow-none px-4"
                          />
                          {errors.title && touched.title ? (
                            <p className="form-error text-danger">
                              {errors.title}
                            </p>
                          ) : error.title ? (
                            <p className="form-error text-danger">
                              {error.title}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-12">
                          <div className="mb-4">
                            <label className="form-label">  Image   <span className="mandatory">*</span></label>
                          
                            {!maincroppedImage && !image && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={showCropMediaModal}
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Upload image from gallery
                                  </button>
                                </div>
                                {/* <input
                                  className="position-absolute top-0"
                                  type="file"
                                  name="offerfile"
                                  onChange={handleofferfilechange}
                                  id="UploadCover"
                                />
                                <span className="upload-icon m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <i className="fa fa-image"></i>
                                </span>
                                <div className="upload-file text-center">
                                  <div className="drag-drop w-100 mt-3">
                                    <p className="m-0">Press Release image</p>
                                  </div>
                                </div> */}
                              </label>
                            )}
                            {maincroppedImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={maincroppedImage} target="_blank">
                                    <img
                                      src={maincroppedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={handleRemoveImage}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {!maincroppedImage && image && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  width: "200px",
                                  position: "relative",
                                  overflow: "hidden",
                                }}
                              >
                                <div>
                                  <a href={image} target="_blank">
                                    <img
                                      src={image}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={handleRemoveImage}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {errors.offerfile && touched.offerfile ? (
                              <p className="form-error text-danger">
                                {errors.offerfile}
                              </p>
                            ) : error.file ? (
                              <p className="form-error text-danger">
                                {error.file}
                              </p>
                            ) : !errors.offerfile &&
                              error.file &&
                              touched.offerfile ? (
                              <p className="form-error text-danger">
                                Please select a file
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4 mb-4">
                          <label className="form-label" htmlFor="pressReleaseDate">
                           Press Release Date  <span className="mandatory">*</span>
                          </label>
                          <input
                            onChange={handleChange}
                            value={values.pressReleaseDate}
                            onBlur={handleBlur}
                            selected={values.pressReleaseDate}
                            type="date"
                            name="pressReleaseDate"
                            id="pressReleaseDate"
                            className="form-control py-3 shadow-none px-4"
                          />
                          {errors.pressReleaseDate &&
                          touched.pressReleaseDate ? (
                            <p className="form-error text-danger">
                              {errors.pressReleaseDate}
                            </p>
                          ) : error.pressReleaseDate ? (
                            <p className="form-error text-danger">
                              {error.pressReleaseDate}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-lg-4 mb-4">
                          <label className="form-label" htmlFor="pressReleaseTime">
                           Press Release Time  <span className="mandatory">*</span>
                          </label>
                          <input
                            onChange={handleChange}
                            value={values.pressReleaseTime}
                            onBlur={handleBlur}
                            type="time"
                            name="pressReleaseTime"
                            selected={values.pressReleaseTime}
                            id="pressReleaseTime"
                            className="form-control py-3 shadow-none px-4"
                          />
                          {errors.pressReleaseTime &&
                          touched.pressReleaseTime ? (
                            <p className="form-error text-danger">
                              {errors.pressReleaseTime}
                            </p>
                          ) : error.pressReleaseTime ? (
                            <p className="form-error text-danger">
                              {error.pressReleaseTime}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-lg-4">
                          <div className="mb-4">
                            <label className="form-label" htmlFor="OfferType">
                              Publish on resort website
                            </label>
                            {/* <select
                              name="resortId"
                              id="OfferType"
                              className="form-control form-select py-3 shadow-none px-4"
                              onChange={handleChange}
                              value={values.resortId}
                            >
                              <option value="">---Select---</option>
                              {getAllResorts &&
                                getAllResorts.data &&
                                getAllResorts.data.data &&
                                getAllResorts.data.data.map((option) => (
                                  <option
                                    key={option.brand_resort_id}
                                    value={option.brand_resort_id}
                                  >
                                    {option.resort_name}
                                  </option>
                                ))}
                            </select> */}
                            <Select
                              name="resortId"
                              id="OfferType"
                              className="3col active  py-0 shadow-none px-6"
                              onChange={handleChangeResort}
                              // value={values.resortId}
                              // value={selectedOptions}
                              value={(getAllResorts?.data?.data || [])
                                .map((option) => ({
                                  value: option.brand_resort_id,
                                  label: option.resort_name,
                                }))
                                .filter(
                                  (option) =>
                                    preSelectedResortIds &&
                                    preSelectedResortIds.includes(option.value)
                                )}
                              onBlur={handleBlur}
                              isMulti
                              options={(getAllResorts?.data?.data || []).map(
                                (option) => ({
                                  value: option.brand_resort_id,
                                  label: option.resort_name,
                                })
                              )}
                              noOptionsMessage={() => null}
                            ></Select>
                          </div>
                          {errors.resortId && touched.resortId ? (
                            <p className="form-error text-danger">
                              {errors.resortId}
                            </p>
                          ) : error.resortId ? (
                            <p className="form-error text-danger">
                              {error.resortId}
                            </p>
                          ) : !errors.resortId &&
                            error.resortId &&
                            touched.resortId ? (
                            <p className="form-error text-danger">
                              Please select a resort
                            </p>
                          ) : null}
                        </div>
                        <div className="col-12 mb-4">
                          <label className="form-label" htmlFor="Description">
                           Description  <span className="mandatory">*</span>
                          </label>
                          {/* <input type="time" name="" id="full-editor" className="form-control py-3 shadow-none px-4" />
                                                    <input type="time" name="" id="basic-editor" className="form-control py-3 shadow-none px-4" /> */}
                          <ReactQuill
                            ref={fullEditorRef}
                            theme="snow"
                            modules={{
                              toolbar: true,
                            }}
                            name="description"
                            style={{ height: "300px" }}
                            value={values.description}
                            // onBlur={handleBlur}
                            //  onChange={handleChange}
                            // onBlur={handleBlur} type="text" name="description" value={values.description}
                          />
                          {errors.description && touched.description ? (
                            <p className="form-error text-danger">
                              {errors.description}
                            </p>
                          ) : error.description ? (
                            <p className="form-error text-danger">
                              {error.description}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Tags
                          </label>
                          <div className="bs-example">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={100}
                              type="text"
                              name="tags"
                              value={values.tags}
                              id="tags"
                              className="form-control py-3 shadow-none px-4"
                              placeholder="Enter tags"
                              data-role="tagsinput"
                            />
                          </div>
                          {/* {errors.tags && touched.tags ? (
                            <p className="form-error text-danger">
                              {errors.tags}
                            </p>
                          ) : error.tags ? (
                            <p className="form-error text-danger">
                              {error.tags}
                            </p>
                          ) : null} */}
                        </div>
                        <div className="col-md-12 mb-4">
                          <label className="form-label" htmlFor="Name">
                            Slug
                          </label>
                          <div className="bs-example">
                            <input
                              onChange={handleChange}
                              onBlur={handleBlur}
                              maxLength={100}
                              type="text"
                              name="slug"
                              value={values.slug}
                              id="slug"
                              className="form-control py-3 shadow-none px-4"
                              placeholder="Enter slug"
                              data-role="tagsinput"
                            />
                          </div>
                          {/* {errors.slug && touched.slug ? (
                            <p className="form-error text-danger">
                              {errors.slug}
                            </p>
                          ) : error.slug ? (
                            <p className="form-error text-danger">
                              {error.slug}
                            </p>
                          ) : null} */}
                        </div>
                        <div className="bottom-actions mt-4 text-end">
                          {/* <input type="submit" onclick={handleSubmit} value="Save" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2" /> */}
                          <input
                            onClick={handleSubmit}
                            type="submit"
                            value="Save"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-5 mt-lg-0">
              <div className="card">
                <div className="card-header py-3 bg-transparent border-bottom">
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="m-0 fw-medium pe-3">Publish</h5>
                    {/* <a href="#" className="btn btn-primary btn-theme fw-semibold text-uppercase px-2 py-2">Preview</a> */}
                  </div>
                </div>
                <div className="card-body">
                  <div className="press-top-action d-flex justify-content-between">
                    {/* <!-- <a href="#" className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-2 py-2 me-2">Save Draft</a> --> */}
                  </div>
                  <div className="press-body">
                    {/* <div className="press-link mt-4 mb-4 d-flex">
                                            <span className="d-flex pt-1" title="Copy link"><i className="fa fa-copy"></i></span>
                                            <a href="#" target="_blank">http://127.0.0.1:5500/atmosphere_core_unveils_grand_India_entrance.html</a>
                                        </div> */}
                    <ul className="list-unstyled">
                      <li>
                        <span>Publish Date</span>
                        <div className="press-actions d-flex">
                          {/* <strong>
                            {getsinglePressRelease &&
                            getsinglePressRelease.data &&
                            getsinglePressRelease.data.data &&
                            getsinglePressRelease.data.data.date
                              ? getsinglePressRelease.data.data.published_date.split(
                                  "T"
                                )[0]
                              : ""}
                          </strong> */}
                          <span className="press-date-wrap d-flex">
                            <input
                              name="publish_date"
                              type="date"
                              className="press-date-input border-0 p-0 shadow-none outline-0"
                              onBlur={handleBlur}
                              // selected={values.publish_date}
                              value={values.publish_date}
                              onChange={handleChange}
                              defaultValue={
                                getsinglePressRelease?.data?.data
                                  ?.published_date !== null
                                  ? getsinglePressRelease.data.data.published_date.split(
                                      "T"
                                    )[0]
                                  : ""
                              }
                            />
                            <div className="edit-action position-absolute end-0 top-0 d-none z-2 bg-white">
                              <FontAwesomeIcon icon={faPencil} />
                            </div>
                            {/* <i className="ms-2 fa fa-pencil"></i> */}
                          </span>
                        </div>
                      </li>
                      <li>
                        <span>Publish Time</span>
                        <div className="press-actions d-flex">
                          {/* <strong>
                            {getsinglePressRelease &&
                            getsinglePressRelease.data &&
                            getsinglePressRelease.data.data &&
                            getsinglePressRelease.data.data.date
                              ? getsinglePressRelease.data.data.published_date
                                  .split("T")[1]
                                  .split(".")[0]
                                  .slice(0, 5)
                              : ""}
                          </strong>{" "} */}
                          <span>
                            <input
                              className="border-0"
                              type="time"
                              onBlur={handleBlur}
                              name="publish_time"
                              value={values.publish_time}
                              // selected={values.publish_time}
                              onChange={handleChange}
                              // defaultValue={
                              //   getsinglePressRelease &&
                              //   getsinglePressRelease.data &&
                              //   getsinglePressRelease.data.data &&
                              //   getsinglePressRelease.data.data
                              //     .published_date !== null
                              //     ? getsinglePressRelease.data.data.published_date
                              //         .split("T")[1]
                              //         .split(".")[0]
                              //         .slice(0, 5)
                              //     : ""
                              // }
                            />
                            {/* <i className="ms-2 fa fa-pencil"></i> */}
                          </span>
                        </div>
                      </li>
                      {/* <li>
                        <span>Publish</span>
                        <div className="press-actions d-flex">
                          <strong>Immediately</strong>
                          <div className="form-check form-switch ms-2">
                            <input
                              name="is_published"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              checked={values.is_published}
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="is_published"
                            />
                          </div>
                        </div>
                      </li> */}
                    </ul>
                  </div>
                </div>
                {/* <div className="card-footer">
                  <div className="press-top-action my-2 d-flex align-items-center justify-content-between">
                    <a
                      onClick={() => handleShowModal(id, values.title)}
                      className="trash-move btn btn-danger text-white px-2 py-2 btn-theme fw-semibold text-uppercase bg-danger border-danger"
                    >
                      Delete
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        show={showModal}
        handleClose={handleCloseModal}
        rowData={selectedRowData}
        rowNameData={selectedRowNameData}
      />

      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {/* <div className="modal fade" id="EditimageModal" tabindex="-1" aria-labelledby="EditimageModalLabel" aria-hidden="true">
                        <div className="modal-dialog mw-100">
                            <div className="modal-content"> */}

          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewSelection
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedImage={setmainCroppedImage}
              setmainCroppedFile={setmainCroppedFile}
              setting_value={
                getAspectRatio &&
                getAspectRatio.data &&
                getAspectRatio.data.data &&
                getAspectRatio.data.data.setting_value
              }
            />
          )}
          {/* </div>
                        </div>
                    </div> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatePressRelease;
