import React from 'react'

function ManageMainBanner() {
  return (
    <>
    <div class="page-body">
                <div class="container-fluid dashboard-default">
                    <div class="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav class="w-100" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                              <li class="breadcrumb-item"><a class="text-decoration-none" href="#">Home</a></li>
                              <li class="breadcrumb-item active" aria-current="page">Manage Banner</li>
                            </ol>
                        </nav>
                        <div class="left-header">
                            <h4 class="fw-normal m-0">Banner</h4>
                        </div>
                    </div>
                    <div class="row mt-lg-5 mt-md-4 mt-3">
                        <div class="col-md-12">
                            <div class="card mb-4">
                                <div class="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                                    <h5 class="m-0 fw-medium">Edit Main Banner</h5>
                                </div>
                                <div class="card-body">
                                    <div class="upload-file-wrapper">
                                        <form action="#" method="get">
                                            <div class="row">
                                                <div class="col-12 mb-4">
                                                    
                                                    <label class="form-label">Add Video</label>
                                                    <div class="view-all-videos position-relative">
                                                        <a data-bs-toggle="modal" data-bs-target="#EditAlbumModal" href="javascript:void(0);" class="text-dark text-decoration-none upload-icon m-auto rounded-1 d-flex align-items-center justify-content-center position-absolute start-0 end-0 m-auto z-1">
                                                            <i class="fa fa-video"></i>
                                                        </a>
                                                        <label for="UploadCover" class="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5">
                                                            <input class="position-absolute top-0" type="file" id="UploadCover"/>
                                                            <div class="upload-file text-center">
                                                                <div class="drag-drop w-100 mt-3">
                                                                    <p class="m-0">Choose video from the Gallery</p>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="col-12 text-end mb-2">
                                                <a href="#" class="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2">Preview</a>
                                                <input type="submit" value="Save" class="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                                    <h5 class="m-0 fw-medium">View Videos</h5>
                                </div>
                                <div class="card-body">
                                    <div class="gallery-assets">
                                        <ul class="list-unstyled m-0 p-0 d-flex flex-wrap align-items-center">
                                            <li>
                                                <div class="album-view border rounded-3 position-relative overflow-hidden">
                                                    <div class="album-set-icon">
                                                        <a href="#" class="position-relative text-decoration-none text-white d-flex px-2 py-3">
                                                            <img src="assets/images/video.png" alt="Album" title="Album" />
                                                            <div class="album-title position-absolute start-0 w-100 z-1 bottom-0 py-3 px-3 text-center">
                                                                 <h6 class="mb-0">Our Family Story</h6>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                 
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                  
                </div>
               
            </div>
    </>
  )
}

export default ManageMainBanner