import React from 'react';

// const Pagination = ({ currentPage, totalPages, onPageChange, visiblePages = 5 }) => {
const Pagination = ({ currentPage, totalPages, onPageChange, visiblePages = 5 ,totalItems}) => {
    const halfVisiblePages = Math.floor(visiblePages / 2);
    const startPage = Math.max(1, currentPage - halfVisiblePages);
    const endPage = Math.min(totalPages, startPage + visiblePages - 1);

    const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);

    const startIndex = (currentPage - 1) * visiblePages + 1;
    const endIndex = Math.min(currentPage * visiblePages, totalItems);
    return (
        <nav>
             
            <ul className="pagination m-0 d-flex justify-content-end align-items-center flex-wrap">
            <div className="pagination-info pe-3 mx-3 mx-md-0 my-2">
                Showing {startIndex} to {endIndex} of {totalItems} items
            </div>
                <li className={`page-item ${currentPage === 1 && 'disabled'}`}>
                    <button className="page-link page-link d-flex align-items-center justify-content-center" onClick={() => onPageChange(currentPage - 1)}>
                        <i className="fa fa-angle-left"></i>
                    </button>
                </li>

                {pageNumbers.map((page) => (
                    <li key={page} className={`page-item ${currentPage === page && 'active'}`}>
                        <button className="page-link page-link d-flex align-items-center justify-content-center" onClick={() => onPageChange(page)}>
                            {page}
                        </button>
                    </li>
                ))}

                <li className={`page-item ${currentPage === totalPages && 'disabled'}`}>
                    <button className="page-link page-link d-flex align-items-center justify-content-center" onClick={() => onPageChange(currentPage + 1)}>
                        <i className="fa fa-angle-right"></i>
                    </button>
                </li>
            </ul>
           
        </nav>
    );
};

export default Pagination;
