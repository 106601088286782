import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const IncrementedInput = (
  {
    initialValue = [],
    distinctValue = false,
    className,
    name,
    value,
    setFieldValue,



  },
  ref
) => {
  if (typeof value === "string" && value === "") {
    value = [];
  }
  const [inputCount, setInputCount] = useState(
    value?.length === 0 ? 1 : value.length
  );
  const [selectedValues, setSelectedValues] = useState(value);



  const handleInputChange = (index, inputValue) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = inputValue;
    // Check if the current input is not the last one
    // if (index !== inputCount - 1) {
    //   // Clear the value of the next input if the current input is cleared
    //   newSelectedValues[index + 1] = "";
    // }
    setSelectedValues(newSelectedValues);

  };


  useEffect(() => {
    if (selectedValues) {
      // const filterSelectedValues = selectedValues.filter(
      //   (item) => item !== 0 && item !== ""
      // );
      // console.log("filter selected values  ", selectedValues)

      setFieldValue(name, selectedValues);
    }
    // Update the Formik field value whenever selectedValues change
  }, [selectedValues]);

  const handleAddClick = () => {
  
    if (selectedValues[inputCount - 1] !== "" && selectedValues[inputCount - 1] !==undefined) {
      setInputCount((prevCount) => prevCount + 1);
      setSelectedValues((prevValues) => [...prevValues, ""]);
    } else {
      toast.dismiss()
      toast.error(
        "Please enter a value in the current input before adding a new one."
      );
    }
  };

  const handleRemoveClick = (index) => {
    setInputCount((prevCount) => prevCount - 1);
    setSelectedValues((prevValues) => prevValues.filter((_, i) => i !== index));
  };

  return (
    <>
      {Array.from({ length: inputCount }, (_, index) => (
        <div className="bed-row d-flex flex-wrap align-items-end" key={index}>
          <div className="bed-add-row d-flex flex-wrap w-100">
            <div className="select-bed-col mb-4">
              <input
                type="text"
                name={`input_${index}`}
                id={`input_${index}`}
                value={value[index]}
                onChange={(e) => handleInputChange(index, e.target.value)}
                className="py-3 shadow-none px-4 form-control"
              />
            </div>

            {index === inputCount - 1 && (
              <>
                <div className="add-more-blocks text-end mb-4">
                  <a
                    onClick={handleAddClick}
                    className="btn btn-primary btn-theme fw-semibold text-uppercase"
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </a>
                </div>
              </>
            )}
            {index !== inputCount - 1 && (
              <>
                <div className="add-more-blocks text-end mb-2">
                  <a
                    onClick={() => handleRemoveClick(index)}
                    className="btn btn-outline-danger text-uppercase"
                  >
                    <i className="fa fa-minus"></i>
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      ))}

    </>
  );
};

export default React.forwardRef(IncrementedInput);
