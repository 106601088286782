


import React, { useState,useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Slide1 from "../../assets/images/offer-1.jpg";

function CropDemo({ src }) {
  const [crop, setCrop] = useState();
  const [image, setImage] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (Slide1 && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(Slide1, crop);
      setCroppedImage(croppedImageUrl);
    }
  };


  const getCroppedImg = (Slide1, crop) => {
    return new Promise((resolve) => {
      const image = new Image();
      image.src = Slide1;

      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL(Slide1);
        resolve(base64Image);
      };
      setImage(image);
     

    });
  };


 



  return (
  <>
    <ReactCrop crop={crop} onChange={(c) => setCrop(c)}  onComplete={onCropComplete}>
      <img src={Slide1} alt="Crop Demo" />
    </ReactCrop>
    {croppedImage && <img src={croppedImage} alt="Cropped" />} {/* Use src attribute */}
  
    
  </>
  );
}

export default CropDemo;