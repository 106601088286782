import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllPropertiesTypes } from "../../services/service";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { hasDuplicates } from "../../helper/CommonHelper";
import SpecificationsTab from "./SpecificationsTab";
import AmenitiesTab from "./AmenitiesTab";
import SignatureFeaturesTab from "./SignatureFeaturesTab";
import pdfIcon from "../../assets/images/pdf-doc.svg";
import videoImg from "../../assets/images/video.png";
import Button from "react-bootstrap/Button";
// crop image start
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelectionForProperties from "../manageAlbum/AlbumViewSelectionForProperties";
import * as querydata from "../dataService/DataService";
import AlbumViewSelectionDynamicAmenityIcon from "../manageAlbum/AlbumViewSelectionDynamicAmenityIcon";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DragAbleImage from "./DragAbleImage";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BsFillInfoCircleFill } from "react-icons/bs";
// crop image end
export const propertySchema = Yup.object({
  propertyName: Yup.string().trim().required("Please enter Property Name"),

  description: Yup.string().trim().required("please enter description"),
  tagline: Yup.string().trim().required("please enter restaurant tagline "),
  // area: Yup.string().trim().required("Please enter area"),
  area: Yup.string()
    .trim()
    .matches(/^\d+$/, "Area must be a number")
    .required("Please enter area"),
});
export default function CreateProperties() {
  const navigate = useNavigate();
  const ToolLink = ({ id, children, title }) => (
    <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
      <a className="text-dark">{children}</a>
    </OverlayTrigger>
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setErrors] = useState(false);

  const [image, setImage] = useState(null);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState([]);
  const [maincroppedFile, setmainCroppedFile] = useState([]);

  const [singleFileProperties, setSingleFileProperties] = useState(null);
  const [singleCropImageProp, setSingleCropImageProp] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [isAmenityIcon, setIsAmenityIcon] = useState("");
  const [amenityFileData, setAmenityFile] = useState("");
  const [amenityCropImage, setAmenityCropImage] = useState("");
  const [mediaType, setMediaType] = useState("");

  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = (propValue, mediaType) => {
    if (propValue === "singleUnitPlanImage") {
      setValForSingleImg(propValue);
      setMultipleImageSection("");
    }
    if (propValue === "multipleImageSectionBtn") {
      setMultipleImageSection(propValue);
      setValForSingleImg("");
    }
    if (propValue === "amenityIcon") {
      setIsAmenityIcon(propValue);
    }
    setCropMediaModal(true);
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "property_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("property_image_dimension")
  );
  // crop code end

  let allPropertiesTypes = useQuery(
    "getAllPropertiesTypes",
    getAllPropertiesTypes
  );
  async function imageUrlToFile(imageUrl) {
    try {
      // Fetch the image
      const response = await fetch(imageUrl);

      // Get the image data as a blob
      const blob = await response.blob();

      // Extract filename from URL
      const fileName = imageUrl.split("/").pop();

      // Create a new File object from the Blob
      const file = new File([blob], fileName, { type: blob.type });

      return file;
    } catch (error) {
      return null;
    }
  }
  const [duplicateIndexes, setDuplicateIndexes] = useState([]);

  let formDataError = false;
  const [unitPlanPdf, setUnitPlanPdf] = useState(null);

  const initialValues = {
    propertyName: "",
    description: "",
    tagline: "",
    features: [],
    amenitiesId: [],
    area: "",
    poolSize: "",
    privatepool: "",
    propertyTypeId: "",
    // adultCount: "1",

    bedTypeId: [],
    bedCounts: [`1`],
    adultCount: [`1`],
    childrenCount: [`0`],
    adultCount1: "1",
    childrenCount1: "0",
  };


  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    getFieldProps,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: propertySchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();

      // if (values.bedTypeId.parent) {
      //   const bedtypejsonString = JSON.stringify(values.bedTypeId);
      //   const jsonObjectBedType = JSON.parse(bedtypejsonString);
      //   const resulBedTypeIdtArray =
      //     Object.values(jsonObjectBedType).map(Number);
      //   const bids = `[${resulBedTypeIdtArray}]`;
      //   const bArrayids = JSON.parse(bids);
      //   bArrayids.forEach((id, index) => {
      //     formData.append(`bedIds[${index}]`, id);
      //   });
      // } else {
      //   toast.dismiss();
      //   return toast.error("bed required");
      // }

      if (values.features) {
        const featuresArray = Object.values(values.features);
        featuresArray.map((value, index) => {
          formData.append(`signatureFeatures[${index}]`, value);
        });
      }
      if (values.amenitiesId) {
        values.amenitiesId.map((amtIds, index) => {
          formData.append(`amenities[${index}]`, amtIds);
        });
      }

      formData.append("propertyName", values.propertyName);
      formData.append("tagLine", values.tagline);
      formData.append("propertyTypeId", values.propertyTypeId);

      if (singleFileProperties) {
        formData.append("unitPlan", singleFileProperties);
      } else {
        formData.append("unitPlan", null);
      }

      const currentFileIds = maincroppedFile?.currentFileIds || [];

      if (currentFileIds !== "undefined" && currentFileIds.length > 0) {
        if (currentFileIds.length >= 2) {
          currentFileIds.map((croppedImage, index) => {
            formData.append(`propertyImages`, croppedImage);
            // formData.append(`imagesSortingIndex`, index);
          });
        } else {
          toast.dismiss();
          return toast.error("Minimum two property images are required.");
        }
      } else {
        toast.dismiss();
        return toast.error("Please upload property images ");
      }

      formData.append("propertyDescription", values.description);
      formData.append("area", values.area);
      formData.append("privatePoolSize", values.poolSize);
      // formData.append("privatepool", values.privatepool);

      if (values.adultCount) {
        const occupancy = values.adultCount.map((adult, index) => ({
          adult: parseInt(adult),
          child: parseInt(values.childrenCount[index]),
        }));

        const duplicates = hasDuplicates(occupancy);
        setDuplicateIndexes(duplicates);
        if (duplicates.length > 0) {
          formDataError = true;
          toast.dismiss();
          toast.error(" Occupancy should be unique");
        } else {
          formDataError = false;
          formData.append("occupancy", JSON.stringify(occupancy));
        }
      }

      if (values.bedTypeId.length > 0 && values.bedTypeId.some(value => value !== "")) {
        // const bedTypeData = values.bedTypeId.map((TypeId, index) => ({
        //   bedTypeId: parseInt(TypeId),
        //   bedCounts: parseInt(values.bedCounts[index]),
        // }));

        // formData.append("bedIds", JSON.stringify(bedTypeData));
        const validBedTypes = values.bedTypeId.filter(value => value !== null);

        // Map over the filtered array
        const bedTypeData = validBedTypes.map((TypeId, index) => ({
            bedTypeId: parseInt(TypeId),
            bedCounts: parseInt(values.bedCounts[index]),
        }));
    
        formData.append("bedIds", JSON.stringify(bedTypeData));
      } else {
        toast.dismiss();
        return toast.error("bed is required");
      }

      if (!formDataError) {
         addMutation.mutate(formData);
      }
    },
  });


  //form post
  const addMutation = useMutation(querydata.insertPropertiesData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      navigate("/manage-properties");
      setmainCroppedImage(null);
      setImage(null);
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const handleRemoveUnitPlanImage = () => {
    setSingleFileProperties(null);
    setSingleCropImageProp(null);
    setUnitPlanPdf(null);
    setMediaType("");
  };
  const handleRemoveImage = (e, index) => {
    // Handle removing the image
    e.preventDefault();
    const updatedImages = [...maincroppedImage];
    if (maincroppedFile && maincroppedFile.currentFileIds) {
      const upadatePropFiles = [...maincroppedFile.currentFileIds];

      // Check if the index is valid

      if (index >= 0 && index < upadatePropFiles.length) {
        // Remove the image at the specified index

        upadatePropFiles.splice(index, 1);
        setmainCroppedFile(upadatePropFiles);
      } else {
        
      }
    } 
    if (index >= 0 && index < updatedImages.length) {
      // Remove the image at the specified index
      updatedImages.splice(index, 1);
      setmainCroppedImage(updatedImages);
    } else {
      
    }
   
  };
  ///editor start
  const fullEditorRef = useRef(null);

  useEffect(() => {
    // Assuming fullEditorRef.current is the ReactQuill instance
    const quill = fullEditorRef.current?.getEditor();

    if (quill) {
      // Listen for text-change event in the Quill editor
      quill.on("text-change", () => {
        const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
        setFieldValue("description", descriptionValue); // Update the Formik state
      });
    }
  }, [fullEditorRef.current]);

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "code-block",
  ];

  ///editor end

  const moveImage = (from, to) => {
    const newImages = [...maincroppedImage];
    const AllFileImg = [...maincroppedFile?.currentFileIds];
    const allmovedImage = AllFileImg.splice(from, 1)[0];
    const movedImage = newImages.splice(from, 1)[0];
    newImages.splice(to, 0, movedImage);
    AllFileImg.splice(to, 0, allmovedImage);
    setmainCroppedImage(newImages);
    setmainCroppedFile({ currentFileIds: AllFileImg });

  };

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link
                    className="text-decoration-none"
                    to="/manage-properties"
                  >
                    Properties
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Manage Properties
                </li>
              </ol>
            </nav>
            <div className="left-header">
              <h4 className="fw-normal m-0">Manage Properties</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Create Your Property</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyName">
                            Villa/Suite Name <span className="mandatory">*</span>
                          </label>
                          <div className="mb-4">
                            <input
                              type="text"
                              className="form-control py-3 shadow-none px-4"
                              name="propertyName"
                              id="PropertyName"
                              value={values.propertyName}
                              onChange={handleChange}
                              maxLength={200}
                            />
                          </div>
                          {errors.propertyName && touched.propertyName ? (
                            <p className="form-error text-danger">
                              {errors.propertyName}
                            </p>
                          ) : error.propertyName ? (
                            <p className="form-error text-danger">
                              {error.propertyName}
                            </p>
                          ) : null}
                        </div>

                        {/* <!-- /col-md-6 --> */}
                        <div className="col-lg-6">
                          <label className="form-label" htmlFor="PropertyType">
                            Property type
                          </label>
                          <div className="mb-4">
                            <select
                              className="form-control form-select py-3 shadow-none px-4"
                              name="propertyTypeId"
                              id="PropertyType"
                              onChange={handleChange}
                              value={values.propertyTypeId}
                            >
                              <option value="">---Select---</option>
                              {allPropertiesTypes.data &&
                                allPropertiesTypes.data.data.map((option) => (
                                  <option
                                    key={option.property_type_id}
                                    value={option.property_type_id}
                                  >
                                    {option.property_type}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <label className="form-label" htmlFor="PropertyUnit">
                            Add Unit Plan
                          </label>
                          <div className="mb-4">
                            {/* {mediaType === "PDF" ? (
                              <>
                                {!unitPlanPdf && (
                                  <label
                                    htmlFor="UploadCover"
                                    className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                    onClick={() =>
                                      showCropMediaModal(
                                        "singleUnitPlanImage",
                                        "PDF"
                                      )
                                    }
                                  >
                                    <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                      >
                                        Choose from gallery
                                      </button>
                                    </div>
                                  </label>
                                )}
                              </>
                            ) : (
                              <> */}
                            {!singleCropImageProp && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={() =>
                                  showCropMediaModal("singleUnitPlanImage")
                                }
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Choose from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            {/* </>
                            )} */}
                            {/* {mediaType === "PDF" ? (
                              <>
                                {unitPlanPdf && (
                                  <div
                                    className="uploaded-img-view"
                                    style={{
                                      height: "200px",
                                      width: "200px",
                                      position: "relative",
                                    }}
                                  >
                                    <div>
                                      <a
                                        href={unitPlanPdf?.data?.media_file_path}
                                        target="_blank"
                                      >
                                        <img
                                          src={pdfIcon}
                                          style={{
                                            width: "68%",
                                            height: "68%",
                                            // objectFit: "cover",
                                          }}
                                        />
                                      </a>
                                      <div
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                        }}
                                      >
                                        <button
                                          className="remove-image-button"
                                          onClick={() =>
                                            handleRemoveUnitPlanImage()
                                          }
                                        >
                                          <i className="fa fa-times"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <> */}
                            {singleCropImageProp && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={singleCropImageProp} target="_blank">
                                    <img
                                      src={
                                        singleCropImageProp.endsWith(".pdf")
                                          ? pdfIcon
                                          : singleCropImageProp
                                      }
                                      // style={{
                                      //   width: "100%",
                                      //   height: "100%",
                                      //   objectFit: "cover",
                                      // }}
                                      style={
                                        singleCropImageProp.endsWith(".pdf")
                                          ? {
                                            width: "68%",
                                            height: "68%",
                                          }
                                          : {
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                          }
                                      }
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={() =>
                                        handleRemoveUnitPlanImage()
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* </>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <label className="form-label" htmlFor="Description">
                          Property Description{" "}
                          <span className="mandatory">*</span>
                        </label>
                        <div className="mb-4">
                          <textarea
                            name="description"
                            id="Description"
                            rows="3"
                            className="form-control py-3 shadow-none px-4"
                            value={values.description}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        {errors.description && touched.description ? (
                          <p className="form-error text-danger">
                            {errors.description}
                          </p>
                        ) : error.description ? (
                          <p className="form-error text-danger">
                            {error.description}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <label className="form-label" htmlFor="Description">
                          Tag Line{" "}
                          <span className="mandatory">*</span>
                        </label>
                        <div className="mb-4">
                          <textarea
                            name="tagline"
                            id="v"
                            rows="3"
                            className="form-control py-3 shadow-none px-4"
                            value={values.tagline}
                            onChange={handleChange}
                          ></textarea>
                        </div>
                        {errors.tagline && touched.tagline ? (
                          <p className="form-error text-danger">
                            {errors.tagline}
                          </p>
                        ) : error.tagline ? (
                          <p className="form-error text-danger">
                            {error.tagline}
                          </p>
                        ) : null}
                      </div>
                      <div className="col-12 mb-4">
                        <label className="form-label mb-3" htmlFor="UploadProperty">
                          Upload property images{" "}
                          <span className="mandatory">*</span>
                        <span className="toolIcon ms-2">
                                  <ToolLink
                                    title="Minimum 2 images are required and these can be rearranged by dragging and dropping them in desired position."
                                    id="t-1"
                                  >
                                    <BsFillInfoCircleFill />
                                  </ToolLink>{" "}
                                </span>
                        </label>
                        <DndProvider backend={HTML5Backend}>
                          <div className="uploaded-property-img d-flex flex-wrap">
                            {maincroppedImage.length === 0 && (
                              <label
                                htmlFor="UploadCover"
                                className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                onClick={() =>
                                  showCropMediaModal("multipleImageSectionBtn")
                                }
                              >
                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                  >
                                    Choose from gallery
                                  </button>
                                </div>
                              </label>
                            )}
                            {maincroppedImage.length > 0 &&
                              maincroppedImage.map((imageURL, index) => (
                                <div
                                  key={index}
                                  className="property-img uploaded d-flex justify-content-center align-items-center position-relative"
                                  // style={{
                                  //   height: "200px",
                                  //   width: "200px",
                                  //   position: "relative",
                                  // }}
                                >
                                  <div className="upload-img">
                                    <DragAbleImage
                                      maincroppedImage={imageURL}
                                      moveImage={moveImage}
                                      index={index}
                                      videoImg={videoImg}
                                    />
                                    {/* <a href={imageURL} target="_blank">
                                    <img
                                      // src={imageURL}
                                      src={
                                        imageURL.endsWith(".mp4")
                                          ? videoImg
                                          : imageURL
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a> */}
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                      }}
                                    >
                                      <button
                                        className="remove-image-button"
                                        onClick={(e) =>
                                          handleRemoveImage(e, index)
                                        }
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))}

                            {maincroppedImage.length > 0 && (
                              <div className="property-img d-flex justify-content-center align-items-center position-relative">
                                <div
                                  className="upload-img"
                                  onClick={() =>
                                    showCropMediaModal(
                                      "multipleImageSectionBtn"
                                    )
                                  }
                                >
                                  <i className="fa fa-plus"></i>
                                </div>
                              </div>
                            )}
                          </div>
                        </DndProvider>
                      </div>
                      
                      
                      <div className="col-12">
                        <ul
                          className="nav nav-pills atmos-tabs d-flex flex-wrap pt-3 border-bottom pb-3"
                          id="myTab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase active"
                              id="specifications-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#specifications-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="specifications-tab-pane"
                              aria-selected="true"
                            >
                              Specifications
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="Amenities-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#Amenities-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="Amenities-tab-pane"
                              aria-selected="false"
                            >
                              Amenities
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link text-uppercase"
                              id="highlight-extra-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#highlight-extra-tab-pane"
                              type="button"
                              role="tab"
                              aria-controls="highlight-extra-tab-pane"
                              aria-selected="false"
                            >
                              Signature Features
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content mt-4 mb-0" id="myTabContent">
                        <div
                          className="tab-pane fade show active"
                          id="specifications-tab-pane"
                          role="tabpanel"
                          aria-labelledby="specifications-tab"
                          tabIndex="0"
                        >
                          <SpecificationsTab
                            errors={errors}
                            error={error}
                            touched={touched}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            initialValues={initialValues}
                            duplicateIndexes={duplicateIndexes}
                            setDuplicateIndexes={setDuplicateIndexes}
                          />
                        </div>

                        <div
                          className="tab-pane fade"
                          id="Amenities-tab-pane"
                          role="tabpanel"
                          aria-labelledby="Amenities-tab"
                          tabIndex="0"
                        >
                          <AmenitiesTab
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            error={error}
                            errors={errors}
                            touched={touched}
                            showCropMediaModal={showCropMediaModal}
                            amenityFileData={amenityFileData}
                            setAmenityCropImage={setAmenityCropImage}
                            amenityCropImage={amenityCropImage}
                            setAmenityFile={setAmenityFile}
                          />
                        </div>

                        <div
                          className="tab-pane fade"
                          id="highlight-extra-tab-pane"
                          role="tabpanel"
                          aria-labelledby="highlight-extra-tab"
                          tabIndex="0"
                        >
                          <SignatureFeaturesTab
                            field={getFieldProps("features")}
                            values={values}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            setValues={setValues}
                            signatureFeaturesData={initialValues.features}
                          />
                        </div>
                      </div>

                      <div className="col-12 text-end mb-2 mt-lg-5 mt-4">
                      
                      <Button
                      type="submit"
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        onClick={handleSubmit}
                      >
                        {isLoading ? <div class="spinner-border text-primary" role="status"></div> : 'SAVE'}
                      </Button>
                      {/* <input
          type="submit"
          value={isLoading ? '' : 'SAVE'}
          className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
        />
      {isLoading && <div className="spinner-border text-primary" role="status"></div>} */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {isAmenityIcon === "amenityIcon" ? (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionDynamicAmenityIcon
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setFolderId={setFolderId}
                  setCropMediaModal1={setCropMediaModal}
                  setAmenityFile={setAmenityFile}
                  setAmenityCropImage={setAmenityCropImage}
                  setting_value={getAspectRatio?.data?.data?.setting_value}
                />
              )}
            </>
          ) : (
            <>
              {folderId && albumId && websiteId && (
                <AlbumViewSelectionForProperties
                  albumId={albumId}
                  websiteId={websiteId}
                  setAlbumId={setAlbumId}
                  setwebsiteId={setwebsiteId}
                  setCropMediaModal1={setCropMediaModal}
                  setmainCroppedImage={setmainCroppedImage}
                  setmainCroppedFile={setmainCroppedFile}
                  setSingleFileProperties={setSingleFileProperties}
                  setSingleCropImageProp={setSingleCropImageProp}
                  setUnitPlanPdf={setUnitPlanPdf}
                  checkValForSingleImg={checkValForSingleImg}
                  checkMultipleImageSection={checkMultipleImageSection}
                  mediaTypeCode={mediaType}
                  setMediaType={setMediaType}
                  setting_value={
                    getAspectRatio &&
                    getAspectRatio.data &&
                    getAspectRatio.data.data &&
                    getAspectRatio.data.data.setting_value
                  }
                />
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
