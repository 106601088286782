import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragHandle } from "./DragHandle";
import styled from "styled-components";
import dragDropImg from "../../assets/images/drag.svg";
const DraggingRow = styled.td`
  background: rgba(127, 207, 250, 0.3);
`;

const TableData = styled.td`
  background: white;
  &:first-of-type {
    min-width: 20ch;
  }
`;

export const DraggableTableRow = ({ row }) => {
  const {
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    isDragging,
  } = useSortable({
    id: row.original.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
  };

  return (
    <tr ref={setNodeRef} style={style} {...row.getRowProps()}>
      {isDragging ? (
        <React.Fragment>
          <DraggingRow colSpan={row.cells.length}>
            <img src={dragDropImg} alt="" title="" />
          </DraggingRow>
          <td></td>
        </React.Fragment>
      ) : (
        row.cells.map((cell, i) => {
          if (i === 0) {
            return (
              <TableData {...cell.getCellProps()}>
                <DragHandle {...attributes} {...listeners} />
                <span>
                  {/* {cell.render("Cell")} */}
                  {/* {i === 0 ? row.index + 1 : cell.render("Cell")} */}
                  {row.index + 1}
                </span>
              </TableData>
            );
          }
          return (
            <TableData {...cell.getCellProps()}>
              {cell.render("Cell")}
              {/* {i === 0 ? row.index + 1 : cell.render("Cell")} */}
            </TableData>
          );
        })
      )}
    </tr>
  );
};
