import React, { useState } from 'react'

function OfferCard({ data }) {
    const [expanded, setExpanded] = useState(false);

    const toggleDescription = () => {
        setExpanded(!expanded);
    };

    const [titleExpanded, setTilteExpanded] = useState(false);

    const toggleTitle = () => {
        setTilteExpanded(!titleExpanded);
    };
    return (
        <>

            <li>
                <div className="grid-view-offer rounded-3 bg-light p-3">
                    <div className="offer-img rounded-3 overflow-hidden position-relative">
                       
                        <img
                            className="w-100"
                            // src="../assets/images/offer-1.jpg"
                            src={data.offer_image}
                            alt=""
                        />
                        {data.case ?
                            <span className="btn btn-sm btn-success position-absolute start-0 top-0 m-3 z-2">
                                {data.case}
                            </span> : ""}
                           <span className='ms-4'>
                           {data.is_active >=0 && data.is_active===null? <span className="btn btn-sm btn-success position-absolute  top-0 m-2 end-0 ">InActive</span>: <span className="btn btn-sm btn-success position-absolute  top-0 m-2 end-0 ">Active</span>}


                           </span>
                    </div>

                    <div className="offer-text d-flex flex-wrap mt-lg-4 mt-3">
                        <div className="text-left-offer">
                            <small className="text-muted text-uppercase">
                                {/* Kanifushi Maldives */}
                                {data.resort_name}
                               
                            </small>
                            <h4 className="mt-1 mb-3 fw-bold">
                                {/* {data.offer_name} */}

                                {data.offer_name.length > 100 && !titleExpanded
                                    ? `${data.offer_name.slice(0, 100)}...`
                                    : data.offer_name}
                                {data.offer_name.length > 100 && (
                                    <button
                                        className="btn btn-link"
                                        onClick={toggleTitle}
                                    >
                                        View More
                                        {/* {titleExpanded ? 'View Less' : 'View More'} */}
                                    </button>
                                )}
                            </h4>
                        </div>
                        <div className="offer-discount text-center">
                            <div className="discount-circle d-flex flex-wrap justify-content-center align-items-center justify-content-center rounded-circle text-white m-0 h4 fw-bold">
                                <div className="off-title">
                                    {data.discount_percent}
                                    <small>
                                        <sub>%</sub>
                                    </small>
                                    <span className="off-text">OFF</span>
                                </div>
                            </div>
                        </div>
                        {/* <p>
                            {data.description}{" "}
                        </p> */}
                        <p>
                        {data.description.length > 100
                                ? (expanded ? data.description : `${data.description.slice(0, 100)}...`)
                                : data.description
                            }
                            {/* {data.description.length > 100 && expanded ? data.description : `${data.description.slice(0, 100)}...`} */}
                            {data.description.length > 100 && !expanded && (
                                <button
                                    className="btn btn-link"
                                    onClick={toggleDescription}
                                >
                                    View More
                                </button>
                            )}

                        </p>
                    </div>
                </div>
            </li>



        </>
    )
}

export default OfferCard