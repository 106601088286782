import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as dataquery from "../dataService/DataService";
import * as Yup from "yup";
import { Button, Modal } from "react-bootstrap";
export const subSectionSchema = Yup.object({
  // subSectionName: Yup.string().trim().required("Please enter title"),
  subSectionName: Yup.string().transform((val, originalVal) => {
    // Check if val is a string before calling trim
    if (typeof val === 'string') {
      return val.trim();
    }
    // If not a string, return as it is
    return val;
  }).required("Please enter title"),
});

const EditSubSectionModal = ({
  row,
  show,
  handleClose,
  pageSubSectionId,
  rowNameData,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  //const id = row.original.website_offer_id;
  const initialValues = {
    subSectionName: rowNameData && rowNameData ? rowNameData : "",
  };
  useEffect(() => {
    setValues({ ...initialValues });
  }, [rowNameData]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: subSectionSchema,
    onSubmit: (values, action) => {
      const formData = new FormData();
      //   formData.append("subSectionTitle", values.subSectionName);
      const subSectionTitle = values.subSectionName;

      updateSubSection.mutate({ pageSubSectionId, subSectionTitle });
    },
  });

  const updateSubSection = useMutation(dataquery.updateSubSection, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      handleClose();
      queryClient.invalidateQueries("getPageSectionDetails");
      queryClient.invalidateQueries("getPageSubSectionDetails");
      setValues("");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  //   const epcArchive = useMutation(dataquery.deletepageSubSection, {
  //     onError: (error) => {
  //       toast.dismiss();
  //       toast.error(error.message);
  //     },
  //     onSuccess: (data) => {
  //       toast.dismiss();
  //       toast.success(data.message);
  //       // navigate("/manage-roles");
  //       queryClient.invalidateQueries("getPageSubSectionDetails");
  //     },
  //   });

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            className="form-control py-3 shadow-none px-4"
            name="subSectionName"
            maxLength={100}
            value={values.subSectionName}
            onChange={handleChange}
          />
          {errors.subSectionName ? (
            <p className="form-error text-danger">{errors.subSectionName}</p>
          ) : null}{" "}
          {/* {errors.subSectionName && touched.subSectionName ? (
            <p className="form-error text-danger">{errors.subSectionName}</p>
          ) : error.subSectionName ? (
            <p className="form-error text-danger">{error.subSectionName}</p>
          ) : null} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditSubSectionModal;
