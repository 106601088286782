import { Link, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.bubble.css';
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "react-query";
import * as querydata from "../dataService/DataService";
import Select from 'react-select';
import backarrow from "../../assets/images/back-arrow.svg";

// crop image start
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewSelection from "../manageAlbum/AlbumViewSelection";
// crop image end

export const pressReleaseSchema = Yup.object({
    title: Yup.string().trim().required("Please enter title "),
    pressReleaseDate: Yup.string().required("Please select press release date"),
    pressReleaseTime: Yup.string().required("Please select press release time"),
    description: Yup.string().trim().required("Please enter description"),

    // resortId: Yup.array().of(Yup.number()).required("Please Select Resort"),

});

const CreatePressRelease = () => {
    const navigate = useNavigate();
    const [offerfile, setofferfile] = useState("");
    const [image, setImage] = useState(null);
    const [error, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);


    // crop code start
    const [folderId, setFolderId] = useState("");
    const [albumId, setAlbumId] = useState("");
    const [websiteId, setwebsiteId] = useState("");
    const [maincroppedImage, setmainCroppedImage] = useState(null);
    const [maincroppedFile, setmainCroppedFile] = useState(null);
    const closeCropMediaModal = () => setCropMediaModal(false);
    const showCropMediaModal = () => {
        setCropMediaModal(true);

    };
    const [cropMediaModal, setCropMediaModal] = useState(false);
    const getAspectRatio = useQuery(
        ["AspectRatio", "press_release_aspect_ratio"], // Using an array as the query key with offerId
        () => querydata.getAspectRatio("press_release_aspect_ratio")
    );
    // crop code end




    const initialValues = {
        title: "",
        pressReleaseDate: "",
        pressReleaseTime: "",
        description: "",
        tags: "",
        slug: "",
        is_published: false,
        resortId: selectedOptions,
        publish_date: "",
        publish_time: "",
    };

    const getAllResorts = useQuery(
        "Resorts",
        querydata.getAllResorts
    );
    const { values, handleBlur, handleChange, handleSubmit, errors, touched, setFieldValue, setValues } =
        useFormik({
            initialValues: initialValues,
            validationSchema: pressReleaseSchema,
            onSubmit: (values, action) => {
                const formData = new FormData();
                formData.append("title", values.title);
                formData.append("date", values.pressReleaseDate);
                formData.append("time", values.pressReleaseTime);
                formData.append("description", values.description);
                formData.append("tags", values.tags);
                formData.append("slug", values.slug);
                formData.append("is_published", values.is_published);
                formData.append("brand_resort_id", values.resortId);
                formData.append("publish_date", values.publish_date);
                formData.append("publish_time", values.publish_time);

                if (maincroppedFile)
                    formData.append("pressImage", maincroppedFile);
                else {
                    toast.dismiss();
                    toast.error( "Please Upload Image");
                    return;
                }

                addMutation.mutate(formData);
            },
        });
    //form post
    const addMutation = useMutation(querydata.insertPressRelease, {
        onError: (error) => {
            toast.dismiss();
            toast.error(error.message);
        },
        onSuccess: (data) => {
            setIsLoading(false);
            toast.dismiss();
            toast.success(data.message);
            navigate("/manage-press-releases");
        },
        onSettled: () => {
            setIsLoading(false); // Set loading to false on success or error
        },
        onMutate: () => {
            setIsLoading(true); // Set loading to true before making the request
        },
    });


    const handleRemoveImage = () => {
        // Handle removing the image
        setImage(null);
        setmainCroppedImage(null);
    };



    ///editor start
    const fullEditorRef = useRef(null);
    ;
    useEffect(() => {
        // Assuming fullEditorRef.current is the ReactQuill instance
        const quill = fullEditorRef.current?.getEditor();

        if (quill) {
            // Listen for text-change event in the Quill editor
            quill.on('text-change', () => {
                const descriptionValue = quill.root.innerHTML; // Get the HTML content of the editor
                setFieldValue('description', descriptionValue); // Update the Formik state
            });
        }
    }, [fullEditorRef.current]);







    const handleChangeResort = (selected) => {
        setSelectedOptions(selected);
        const resortIdValue = selected.length > 0 ? selected.map(option => option.value) : null;

        // Update state or form values
        setValues({
            ...values,
            resortId: resortIdValue,
        });
    };



    return (
        <>

            <div className="page-body">
                <div className="container-fluid dashboard-default">
                    <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
                        <nav className="w-100" aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link className="text-decoration-none" to="/manage-press-releases">manage-press-releases</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Create Press Release</li>
                            </ol>
                        </nav>
                        <div className="left-header d-flex align-items-center">
                            <Link to='/manage-press-releases' className="back-arrow d-inline-block me-2"><img src={backarrow} alt="" /></Link>
                            <h4 className="fw-normal m-0">Press Release</h4>
                        </div>
                    </div>
                    <div className="row mt-lg-5 mt-md-4 mt-3">
                        <div className="col-xl-9 col-lg-7">
                            <div className="card mb-4">
                                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                                    <h5 className="m-0 fw-medium">Create Press Release</h5>
                                    <div className="right-actions d-none">
                                        <a href="#" className="btn btn-primary btn-theme outline-btn fw-semibold text-uppercase px-3 py-2 me-2">Save Draft</a>
                                        <a href="#" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2">Preview</a>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="upload-file-wrapper">
                                        <form action="#" method="get">
                                            <div className="row">
                                                <div className="col-md-12 mb-4">
                                                    <label className="form-label" htmlFor="Name"> Title <span className="mandatory">*</span></label>
                                                    <input name="title" value={values.title}
                                                        onChange={handleChange}
                                                        maxLength={100}
                                                        onBlur={handleBlur} id="Name" className="form-control py-3 shadow-none px-4" />
                                                    {errors.title && touched.title ? (
                                                        <p className="form-error text-danger">
                                                            {errors.title}
                                                        </p>
                                                    ) : error.title ? (
                                                        <p className="form-error text-danger">
                                                            {error.title}
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="col-12">
                                                    <div className="mb-4">
                                                        <label className="form-label">Image <span className="mandatory">*</span> </label>
                                                        {!maincroppedImage && !image && (
                                                            <label htmlFor="UploadCover" className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5" onClick={showCropMediaModal}>
                                                                <div className="m-auto rounded-1 d-flex align-items-center justify-content-center" >
                                                                    <button type="button" className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3">Upload image from gallery</button>
                                                                </div>


                                                            </label>
                                                        )}
                                                        {maincroppedImage && (
                                                            <div className="uploaded-img-view" style={{ width: '200px', position: 'relative' }}>

                                                                <div>
                                                                    <a href={maincroppedImage} target="_blank">
                                                                        <img src={maincroppedImage} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                                                                    </a>
                                                                    <div style={{ position: 'absolute', top: '0', right: '0' }}>
                                                                        <button className="remove-image-button" onClick={handleRemoveImage}>
                                                                            <i className="fa fa-times"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )}
                                                        {!maincroppedImage && image && (
                                                            <div
                                                                className="uploaded-img-view"
                                                                style={{
                                                                    height: "200px",
                                                                    width: "200px",
                                                                    position: "relative",
                                                                    overflow: "hidden",
                                                                }}
                                                            >
                                                                <div>
                                                                    <a href={image} target="_blank">
                                                                        <img
                                                                            src={image}
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                objectFit: "cover",
                                                                            }}
                                                                        />
                                                                    </a>
                                                                    <div
                                                                        style={{
                                                                            position: "absolute",
                                                                            top: "0",
                                                                            right: "0",
                                                                        }}
                                                                    >
                                                                        <button
                                                                            className="remove-image-button"
                                                                            onClick={handleRemoveImage}
                                                                        >
                                                                            <i className="fa fa-times"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {errors.offerfile && touched.offerfile ? (
                                                            <p className="form-error text-danger">
                                                                {errors.offerfile}
                                                            </p>
                                                        ) : error.file ? (
                                                            <p className="form-error text-danger">
                                                                {error.file}
                                                            </p>
                                                        ) : !errors.offerfile &&
                                                            error.file &&
                                                            touched.offerfile ? (
                                                            <p className="form-error text-danger">
                                                                Please select a file
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-xl-4 mb-4">
                                                    <label className="form-label" htmlFor="pressReleaseDate">Press Release Date <span className="mandatory">*</span> </label>
                                                    <input onChange={handleChange}
                                                        onBlur={handleBlur} selected={values.pressReleaseDate} type="date" name="pressReleaseDate" id="pressReleaseDate" className="form-control py-3 shadow-none px-4" />
                                                    {errors.pressReleaseDate && touched.pressReleaseDate ? (
                                                        <p className="form-error text-danger">
                                                            {errors.pressReleaseDate}
                                                        </p>
                                                    ) : error.pressReleaseDate ? (
                                                        <p className="form-error text-danger">
                                                            {error.pressReleaseDate}
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="col-xl-4 mb-4">
                                                    <label className="form-label" htmlFor="pressReleaseTime">Press Release  Time <span className="mandatory">*</span></label>
                                                    <input onChange={handleChange}
                                                        onBlur={handleBlur} type="time" name="pressReleaseTime" selected={values.pressReleaseTime} id="pressReleaseTime" className="form-control py-3 shadow-none px-4" />
                                                    {errors.pressReleaseTime && touched.pressReleaseTime ? (
                                                        <p className="form-error text-danger">
                                                            {errors.pressReleaseTime}
                                                        </p>
                                                    ) : error.pressReleaseTime ? (
                                                        <p className="form-error text-danger">
                                                            {error.pressReleaseTime}
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="col-xl-4">
                                                    <div className="mb-4 publish-resort-select-box">
                                                        <label className="form-label" htmlFor="OfferType">Publish on resort website</label>
                                                        <Select name="resortId" id="OfferType" className="3col active shadow-none "
                                                            onChange={handleChangeResort}
                                                            // value={values.resortId}
                                                            value={selectedOptions}
                                                            multiple="multiple"
                                                            isMulti
                                                            options={(getAllResorts?.data?.data || []).map((option) => ({
                                                                value: option.brand_resort_id,
                                                                label: option.resort_name,
                                                            }))}
                                                        >

                                                        </Select>

                                                    </div>

                                                </div>
                                                <div className="col-12 mb-4">
                                                    <label className="form-label" htmlFor="Description"> Description <span className="mandatory">*</span></label>

                                                    <ReactQuill
                                                        ref={fullEditorRef}

                                                        theme="snow"
                                                        modules={{
                                                            toolbar: true,
                                                        }} style={{ height: '300px' }}

                                                    />
                                                    {errors.description && touched.description ? (
                                                        <p className="form-error text-danger">
                                                            {errors.description}
                                                        </p>
                                                    ) : error.description ? (
                                                        <p className="form-error text-danger">
                                                            {error.description}
                                                        </p>
                                                    ) : null}
                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <label className="form-label" htmlFor="Name">Tags</label>
                                                    <div className="bs-example">
                                                        <input onChange={handleChange}
                                                            onBlur={handleBlur} maxLength={100} type="text" name="tags" value={values.tags} id="tags" className="form-control py-3 shadow-none px-4" placeholder="Enter tags" data-role="tagsinput" />
                                                    </div>

                                                </div>
                                                <div className="col-md-12 mb-4">
                                                    <label className="form-label" htmlFor="Name">Slug</label>
                                                    <div className="bs-example">
                                                        <input onChange={handleChange}
                                                            onBlur={handleBlur} type="text" maxLength={100} name="slug" value={values.slug} id="slug" className="form-control py-3 shadow-none px-4" placeholder="Enter slug" data-role="tagsinput" />
                                                    </div>

                                                </div>
                                                <div className="bottom-actions mt-4 text-end">
                                                    <input onClick={handleSubmit} type="submit" value="Save" className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2" />

                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-5 mt-lg-0">
                            <div className="card">
                                <div className="card-header py-3 bg-transparent border-bottom">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="m-0 fw-medium pe-3">Publish</h5>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="press-top-action d-flex justify-content-between">

                                    </div>
                                    <div className="press-body">

                                        <ul className="list-unstyled">

                                            <li className="flex-wrap">

                                                <span>Publish Date</span>
                                                <div className="press-actions d-flex">

                                                    <span className="press-date-wrap d-flex">
                                                        <input
                                                            name="publish_date"
                                                            type="date"
                                                            className="press-date-input border-0 p-0 shadow-none outline-0"
                                                            onChange={handleChange}
                                                            value={values.publish_date}
                                                            onBlur={handleBlur}
                                                            selected={values.publish_date}
                                                        />
                                                        <div className="edit-action position-absolute end-0 top-0 d-none z-2 bg-white">
                                                        </div>


                                                    </span>
                                                </div>
                                                {errors.publish_date && touched.publish_date ? (
                                                    <p className="form-error text-danger">
                                                        {errors.publish_date}
                                                    </p>
                                                ) : error.publish_date ? (
                                                    <p className="form-error text-danger">
                                                        {error.publish_date}
                                                    </p>
                                                ) : null}
                                            </li>
                                            <li className="flex-wrap">
                                                <span>Publish Time</span>
                                                <div className="press-actions d-flex">

                                                    <span>
                                                        <input
                                                            className="border-0"
                                                            type="time"
                                                            name="publish_time"
                                                            value={values.publish_time}
                                                            onBlur={handleBlur}
                                                            selected={values.publish_time}
                                                            onChange={handleChange}
                                                        />
                                                    </span>
                                                </div>
                                                {errors.publish_time && touched.publish_time ? (
                                                    <p className="form-error text-danger">
                                                        {errors.publish_time}
                                                    </p>
                                                ) : error.publish_time ? (
                                                    <p className="form-error text-danger">
                                                        {error.publish_time}
                                                    </p>
                                                ) : null}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal size='xl' show={cropMediaModal} onHide={closeCropMediaModal}>
                <Modal.Header className="modal-header px-5 py-4" closeButton>
                    <Modal.Title className="modal-title fs-5" >
                        {'Select Image'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=" overflow-x-auto overflow-y-auto" >



                    {!folderId && <MediaFolderView setFolderId={setFolderId} />}
                    {folderId && !albumId && !websiteId && <ManageAlbumSelection folderId={folderId} setAlbumId={setAlbumId} setwebsiteId={setwebsiteId} setFolderId={setFolderId} />}
                    {folderId && albumId && websiteId && <AlbumViewSelection albumId={albumId} websiteId={websiteId} setAlbumId={setAlbumId} setwebsiteId={setwebsiteId} setFolderId={setFolderId} setCropMediaModal1={setCropMediaModal}
                        setmainCroppedImage={setmainCroppedImage} setmainCroppedFile={setmainCroppedFile} setting_value={getAspectRatio && getAspectRatio.data && getAspectRatio.data.data && getAspectRatio.data.data.setting_value}
                    />}

                </Modal.Body>

            </Modal>
        </>
    );
};

export default CreatePressRelease;
