import React, { useMemo, useState } from "react";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";
import { DraggableTableRow } from "./DraggableTableRow";
import { StaticTableRow } from "./StaticTableRow";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
export function Table({
  columns,
  data,
  setData,
  pageNumber = 0,
  updateSortingTable,
  isShowingHeading,
  resortsData = [],
  componentName,
}) {
  const [activeId, setActiveId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isListView, setIsListView] = useState(true);
  const queryClient = useQueryClient();
  const items = useMemo(() => data?.map(({ id }) => id), [data]);
  //  itemsPerPage = 20;
  const initialPageSize = 20;
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    state: { filters },
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: initialPageSize },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  let invalidateQuery = "";
  function handleDragEnd(event) {
    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      if (componentName === "property") {
        invalidateQuery = "getAllProperties";
      } else if (componentName === "offer") {
        invalidateQuery = "getAllOffers";
      } else if (componentName === "dining") {
        invalidateQuery = "getAllRestaurents";
      } else if (componentName === "pressrelease") {
        invalidateQuery = "getAllPressRelease";
      }
      setData((data) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);

        changeSortingIndexMutation.mutate({
          id: active.id,
          newSortingIndex: newIndex + 1,
        });
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  }
  const changeSortingIndexMutation = useMutation(updateSortingTable, {
    // Configuration options for the mutation
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      // Handle success
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries(invalidateQuery);
    },
    onSettled: () => {
      setIsLoading(false);
    },
    onMutate: () => {
      setIsLoading(true);
    },
  });
  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.id === activeId);
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  // Render the UI for your table

  const [currentPageIndex, setCurrentPageIndex] = useState(pageNumber);
  // Handle pagination controls
  const handleGoToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    gotoPage(pageIndex);
  };

  const handleNextPage = () => {
    setCurrentPageIndex(pageIndex + 1);
    nextPage();
  };
  const handlePreviousPage = () => {
    setCurrentPageIndex(pageIndex - 1);
    previousPage();
  };

  const setdatafilter = (e) => {
    setFilter("resort_name", e);
    var i = 1;
  };

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <div className="card">
        {isShowingHeading && (
          <>
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">All offers</h5>
              <div className="right-table-actions d-flex">
                <div className="filter-buttons d-flex align-items-center">
                  <form action="#" method="get">
                    <div className="position-relative">
                      <input
                        value={state.globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        type="text"
                        className="form-control rounded-1"
                        placeholder="Type to Search..."
                      />
                    </div>
                  </form>
                  <div className="atmosphere-hr mx-3">
                    {/* <select className="form-control rounded-1 form-select" name="" id="" onChange={(e)=>setFilter("resort_name",e.target.value)}> */}
                    <select
                      className="form-control rounded-1 form-select"
                      name=""
                      id=""
                      onChange={(e) => setdatafilter(e.target.value)}
                    >
                      <option value="">All Resorts</option>
                      {resortsData &&
                        resortsData.map((resort) => (
                          <option
                            key={resort.brand_resort_id}
                            value={resort.resort_name}
                          >
                            {resort.resort_name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="card-body">
          <div
            id="list-grid-view"
            className={
              isListView
                ? "tabledata list-view-filter"
                : "tabledata grid-view-filter"
            }
          >
            <table {...getTableProps()} className="table">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {/* <th></th> */}

                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                <SortableContext
                  items={items}
                  strategy={verticalListSortingStrategy}
                >
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <DraggableTableRow key={row.original.id} row={row} />
                    );
                  })}
                </SortableContext>
              </tbody>
            </table>
            <DragOverlay>
              {activeId && (
                <table
                  style={{ width: "100%" }}
                  className="table drag-drop-optional-table"
                >
                  <tbody>
                    <StaticTableRow row={selectedRow} />
                  </tbody>
                </table>
              )}
            </DragOverlay>
            <div>
              <label
                className="d-flex align-items-center mt-2"
                style={{ maxWidth: "190px" }}
              >
                Show{" "}
                <select
                  value={pageSize}
                  onChange={(e) => {
                    setCurrentPageIndex(0); // Reset to the first page when changing rows per page
                    setPageSize(Number(e.target.value));
                  }}
                  className="form-select ms-3 me-2"
                >
                  {[20, 40, 60, 80, 100].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize}
                    </option>
                  ))}
                </select>{" "}
                entries
              </label>
            </div>
            <div className="atmosphere-pagination">
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div>
                  Showing {pageIndex * pageSize + 1} to{" "}
                  {Math.min((pageIndex + 1) * pageSize, rows.length)} of{" "}
                  {rows.length} entries
                </div>
                <div className="atmosphere-pagination">
                  <ul className="pagination m-0 d-flex justify-content-end">
                    <li className="page-item">
                      <button
                        onClick={() => handlePreviousPage()}
                        disabled={!canPreviousPage}
                        className="page-link d-flex align-items-center justify-content-center"
                      >
                        <i className="fa fa-angle-left"></i>
                        {/* <i className="fa-solid fa-angle-left"></i> */}
                      </button>
                    </li>
                    {Array.from({ length: pageCount }, (_, index) => (
                      <li className="page-item" key={index}>
                        <button
                          className={
                            pageIndex === index
                              ? "page-link d-flex align-items-center justify-content-center active"
                              : ""
                          }
                          key={index}
                          onClick={() => handleGoToPage(index)}
                          disabled={pageIndex === index}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}

                    <li className="page-item">
                      <button
                        className="page-link d-flex align-items-center justify-content-center"
                        onClick={() => handleNextPage()}
                        disabled={!canNextPage}
                      >
                        <i className="fa fa-angle-right"></i>

                        {/* <i className="fa-solid fa-angle-right"></i> */}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DndContext>
  );
}
