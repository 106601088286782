import React from "react";
import { useMutation ,useQueryClient} from 'react-query'
import { toast } from 'react-toastify';
import * as dataquery from "../dataService/DataService";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { Button, Modal } from 'react-bootstrap';

const DeleteModal = ({row,show, handleClose, rowData,rowNameData}) => {
    const queryClient = useQueryClient();
    //const id = row.original.website_offer_id;
    const handleArchive = (data) => {
        epcArchive.mutate(data);
        handleClose();
      };
      const epcArchive = useMutation(dataquery.deleteMenu, {
        onError: (error) => {
          toast.dismiss();
          toast.error(error.message);
        },
        onSuccess: (data) => {
          toast.dismiss();
          toast.success(data.message);
          queryClient.invalidateQueries('getAllMenues1')
        },
      });
  return (
    <>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete - {rowNameData}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Once you delete this data, you won't be able to recover it. The Child menu items will also be deleted if any!. Are
                you sure you want to go ahead?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} >
            Close
          </Button>
          <Button variant="primary" onClick={() => handleArchive(rowData)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>


     
    </>
  );
};

export default DeleteModal;
