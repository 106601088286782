export function getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}


export function hasDuplicates(arr) {
    const seen = new Set();
    const duplicates = [];
    arr.forEach((obj, index) => {
      const objString = JSON.stringify(obj);
      if (seen.has(objString)) {
        duplicates.push(index);
      } else {
        seen.add(objString);
      }
    });
    return duplicates;
  }