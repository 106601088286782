import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useFilters,
  useSortBy,
} from "react-table";

const Table1 = ({ columns, data, pageNumber = 0, resortsData }) => {

  const [isListView, setIsListView] = useState(true);
  const [filteredData, setFilteredData] = useState();

  const toggleView = () => {
    setIsListView(true);
  };
  const toggleGridView = () => {
    setIsListView(false);
  };

  //const data1 = filteredData ? filteredData : data;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    setFilter,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    state: { filters },
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const [currentPageIndex, setCurrentPageIndex] = useState(pageNumber);
  // Handle pagination controls
  const handleGoToPage = (pageIndex) => {
    setCurrentPageIndex(pageIndex);
    gotoPage(pageIndex);
  };

  const handleNextPage = () => {
    setCurrentPageIndex(pageIndex + 1);
    nextPage();
  };

  const handlePreviousPage = () => {
    setCurrentPageIndex(pageIndex - 1);
    previousPage();
  };

  const setdatafilter = (e) => {

    setGlobalFilter(e);

    var i = 1;
  };

  return (
    <div className="card-body ">
      <div id="list-grid-view" className="tabledata list-view-filter">
        {
          <div className="list-offers">
            {/* <LeadTable tableName={"List of Customers"} columns={columns} data={data} /> */}
            <div className="table-responsive">
              <table {...getTableProps()} className="table">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          key={column.id}
                          style={{ width: column.width }}
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon icon={faSortDown} />
                              ) : (
                                <FontAwesomeIcon icon={faSortUp} />
                              )
                            ) : (
                              // <FontAwesomeIcon icon={faSort} />
                              ""
                            )}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell, index) => (
                          <td {...cell.getCellProps()}>
                            {index === 0 ? row.index + 1 : cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="d-flex align-items-center flex-wrap justify-content-between mt-4 mb-3 px-md-0 px-3">
              <div className="ps-md-3 show-enteries">
                <label
                  className="d-flex align-items-center"
                  style={{ maxWidth: "190px" }}
                >
                  Show{" "}
                  <select
                    value={pageSize}
                    onChange={(e) => {
                      setCurrentPageIndex(0); // Reset to the first page when changing rows per page
                      setPageSize(Number(e.target.value));
                    }}
                    className="form-select ms-3 me-3"
                  >
                    {[10, 20, 30, 40, 50].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>{" "}
                  entries
                </label>
              </div>
              <div className="atmosphere-pagination album-pagination pe-md-3 pe-0 d-flex justify-content-md-end">
                <div className="pagi-wrap d-flex align-items-center justify-content-between flex-wrap">
                  <div className="pagination-info pe-3">
                    Showing {pageIndex * pageSize + 1} to{" "}
                    {Math.min((pageIndex + 1) * pageSize, rows.length)} of{" "}
                    {rows.length} entries
                  </div>
                  <div className="atmosphere-pagination mt-0">
                    <ul className="pagination m-0 d-flex justify-content-end">
                      <li className="page-item">
                        <button
                          onClick={() => handlePreviousPage()}
                          disabled={!canPreviousPage}
                          className="page-link d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-angle-left"></i>
                          {/* <i className="fa-solid fa-angle-left"></i> */}
                        </button>
                      </li>
                      {Array.from({ length: pageCount }, (_, index) => (
                        <li className="page-item" key={index}>
                          <button
                            className={
                              pageIndex === index
                                ? "page-link d-flex align-items-center justify-content-center active"
                                : "page-link d-flex align-items-center justify-content-center"
                            }
                            key={index}
                            onClick={() => handleGoToPage(index)}
                            disabled={pageIndex === index}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}

                      <li className="page-item">
                        <button
                          className="page-link d-flex align-items-center justify-content-center"
                          onClick={() => handleNextPage()}
                          disabled={!canNextPage}
                        >
                          <i className="fa fa-angle-right"></i>

                          {/* <i className="fa-solid fa-angle-right"></i> */}
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Table1;
