import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
// import Table from "../../components/Table2";
import { Table } from "../../components/dragTable/Table";
import { getAllProperties } from "../../services/service";
// import { useQuery } from "react-query";
import DeleteModal from "./DeleteModal";
import dummyImage from "../../assets/images/album.png";
import { updateSortingTable,updatePropertyIsActive } from "../dataService/DataService";
import DragTable from "../../components/DragTable";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "react-query";
export default function ManageProperties() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState({});
  const [selectedRowNameData, setSelectedRowNameData] = useState({});
  const [data, setData] = useState([]);
  const handleShowModal = (rowData) => {
    setSelectedRowData(rowData.original.property_id);
    setSelectedRowNameData(rowData.original.property_name);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const handleNavigateToEdit = (propertyId) => {
    navigate(`/manage-properties/edit-property/${propertyId}`);
  };
  const handleCheckboxChange = (row) => {
    setSelectedRows((prev) => {
      if (prev.includes(row.website_press_release_id)) {
        // If the row is already selected, remove it
        return prev.filter(
          (selectedRow) => selectedRow !== row.website_press_release_id
        );
      } else {
        // If the row is not selected, add it
        return [...prev, row.website_press_release_id];
      }
    });
  };
  const brandResortId = "1";
  let allProperties = useQuery(["getAllProperties", +brandResortId], () =>
    getAllProperties(brandResortId)
  );

  const handleStatus = (e, row) => {
    const status = Number(e.target.value);
    const id = row.original.property_id;
    row.original.is_active = status;
    const data = changeApplicationStatusMutate.mutate({ id, status });
  };

  const changeApplicationStatusMutate = useMutation(updatePropertyIsActive, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess(data) {
      toast.dismiss();

      toast.success(data.message);
    },
  });

  const columns = React.useMemo(
    () => [
      { Header: "Sr. No", accessor: "srNo" },
      

      {
        Header: "Image",
        accessor: "image_path",
        Cell: ({ row }) => (
          <>
            {row.original.property_images ? (
              <img
                src={row.original.property_images[0]["image_path"]}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // Prevents looping
                  currentTarget.src = dummyImage; // Replace with the path to your dummy image
                }}
                width="100"
              />
            ) : (
              <img src={dummyImage} width="100" />
            )}
          </>
        ),
      },
      {
        Header: "Property Name",
        Cell: ({ row }) => `${row.original.property_name}`,
      },
      { Header: "Property Type", accessor: "property_type" },
      {
        Header: "Status",
        Cell: ({ row }) => (
          <select
            onChange={(e) => handleStatus(e, row)}
            value={row.original.is_active}
            className="form-select"
            style={{ width: "110px" }}
          >
            <option key="1" value="1">
              Active
            </option>
            <option key="0" value="0">
              Inactive
            </option>
          </select>
        ),
      },
      // {
      //   Header: "Description",
      //   accessor: "description",
      //   Cell: ({ row }) => (
      //     <>
      //       {row.original.property_description.length > 20
      //         ? `${row.original.property_description.substring(0, 20)}...`
      //         : row.original.property_description}
      //     </>
      //   ),
      // },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => handleNavigateToEdit(row.original.property_id)}
              className="btn btn-primary btn-sm btn-theme"
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </button>
            <button
              variant="info"
              onClick={() => handleShowModal(row)}
              className="btn btn-primary bg-danger border-danger btn-sm btn-theme ms-1"
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </>
        ),
      },
    ],
    []
  );

  if (!allProperties.data) {
    <div>Loading</div>;
  }
  if (!allProperties.isLoading) {
    <div>Loading</div>;
  }
  useEffect(() => {
    if (allProperties?.data) {
      setData(allProperties?.data?.data);
    }
  }, [allProperties]);

  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <h4 className="fw-normal m-0">Manage Properties</h4>
          </div>
          <div className="card mt-lg-5 mt-4">
            <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
              <h5 className="m-0 fw-medium">All Properties</h5>
              <div className="right-table-actions">
                <Link
                  to="/manage-properties/create-properties"
                  className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                >
                  Add New
                </Link>
              </div>
            </div>
            {allProperties && allProperties.data && (
              <>
                {/* <Table data={allProperties?.data?.data} columns={columns} /> */}
                <Table
                    data={data}
                    setData={setData}
                    columns={columns}
                    isShowingHeading={false}
                    updateSortingTable={updateSortingTable}
                    componentName={"property"}
                  />
                {/* <DragTable
                  data={allProperties?.data?.data}
                  columns={columns}
                  isShowingHeading={false}
                  updateSortingTable={updateSortingTable}
                  componentName={"property"}
                /> */}
              </>
            )}
            <DeleteModal
              show={showModal}
              handleClose={handleCloseModal}
              rowData={selectedRowData}
              rowNameData={selectedRowNameData}
            />
          </div>
        </div>
      </div>
    </>
  );
}
