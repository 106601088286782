import React from 'react'
import { Link } from 'react-router-dom'

function SideBarRaiseTicket() {
    return (
        <>
            <div className="col-xxl-3 mt-4 mt-xxl-0">
                <div className="card">
                    <div className="card-body">
                        <div className="support-ticket bg-white">
                            <h5 className="fw-semibold"><strong>X</strong>  24x7  Support Ticket</h5>
                            <div className="card-para mt-3 pb-2">
                                <p>Give us a call 24 hours a day, 7 days a week. Our highly trained, courteous support staff is waiting to take your call.</p>
                            </div>
                            <div className="card-footer bg-transparent pt-4">
                                <div className="contact-now d-flex">
                                    <div className="contact-icon">
                                        <span className="d-flex align-items-center justify-content-center rounded-3"><i className="fa fa-phone text-white"></i></span>
                                    </div>
                                    <div className="contact-text">24/7 Technical support
                                        <a className="text-decoration-none fw-semibold" href="tel:(480)5058877">(480) 505-8877</a>
                                    </div>

                                </div>
                                <div className="raise-ticket mt-4">
                                    <Link to="#" className="btn btn-primary btn-theme fw-semibold text-uppercase w-100 px-3 py-3">Raise a Ticket</Link>
                                </div>
                            </div>
                        </div>
                        {/* <!--/support-ticket  --> */}
                    </div>
                    {/* <!-- /card-body --> */}
                </div>
                {/* <!-- /card --> */}
            </div>
        </>
    )
}

export default SideBarRaiseTicket
