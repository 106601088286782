import { React, useState, useEffect } from "react";
import backarrow from "../../assets/images/back-arrow.svg";
import { useParams, useNavigate, Link, Navigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import pdfImg from "../../assets/images/pdf-doc.svg";

import Row from "react-bootstrap/Row";

import * as querydata from "../dataService/DataService";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  getRestaurantType,
  getRestaurantTags,
  insertRestaurantData,
} from "../dataService/DataService";
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query";
import Modal from "react-bootstrap/Modal";
import MediaFolderView from "../manageAlbum/MediaFolderViewSelection";
import ManageAlbumSelection from "../manageAlbum/ManageAlbumSelection";
import AlbumViewForDinning from "../manageAlbum/AlbumViewForDinning";

import MultipleRestaurantTag from "./MultiRestaurantTag";
import RestaurantDetail from "./RestaurantDetail";
import { toast } from "react-toastify";
import RestaurentTags from "./IncrementedInput";

export const RestaurantSchema = Yup.object({
  RestaurantName: Yup.string().required("Please enter restaurant name").trim(),
  // RestaurantTags:Yup.string().required("Please enter restaurant name").trim(),
  // RestaurantTypeId: Yup.number().required("Please select restaurant type").typeError('Please select restaurant type'),
  RestaurantDescription: Yup.string().required(
    "Please enter restaurant description"
  ).trim(),
  RestaurantTagLine: Yup.string().required("Please enter restaurant Tagline").trim(),
  dataArray: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("This field requires a heading").trim(),
      tagDescription: Yup.string().required(
        "This field requires a description"
      ).trim(),
      tagFeatures: Yup.array().of(Yup.string().required("Please add atleast one tag Feature").trim()).min(1, "At least one tag feature is required"),
    })
  ),

});

function AddNewRestaurant() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState({});
  const [error, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  let AllRestaurantTypeId = useQuery("getRestaurant", getRestaurantType);

  // crop code start
  const [folderId, setFolderId] = useState("");
  const [albumId, setAlbumId] = useState("");
  const [websiteId, setwebsiteId] = useState("");
  const [maincroppedImage, setmainCroppedImage] = useState(null);
  const [maincroppedFile, setmainCroppedFile] = useState(null);
  const [maincroppedSecImage, setmainCroppedSecImage] = useState(null);
  const [maincroppedThirdImage, setmainCroppedThirdImage] = useState(null);
  const [maincroppedSecFile, setmainCroppedSecFile] = useState(null);
  const [maincroppedThirdFile, setmainCroppedThirdFile] = useState(null);
  const [checkValForSingleImg, setValForSingleImg] = useState("");
  const [checkValForSingleImg3, setValForSingleImg3] = useState("");
  const [checkMultipleImageSection, setMultipleImageSection] = useState("");
  const [checkValForIcon, setValForIcon] = useState("");
  const [mainCroppedIcon, setMainCroppedIcon] = useState(null);
  const [icon, setIcon] = useState(null);
  const [mainCroppedIconFile, setmainCroppedIconFile] = useState(null);
  const [currentFileId, setCurrentFileId] = useState("");
  const[BannerImage,setBannerImage]=useState(null);
  const [maincroppedBannerImage,setMainCroppedBannerImage]=useState(null);
  const [mainCroppedBannerImageFile,setmainCroppedBannerImageFile]=useState(null);
  const [checkValForBannerImage,setValForBannerImage]=useState(null);
  const [settingValue,setSettingValue] = useState("")
  const [imgArray, setImgArray] = useState(null)//use to get the index of the selected detail array Image
  const closeCropMediaModal = () => setCropMediaModal(false);


  const showCropMediaModal = (id, val, index) => {
    setCurrentFileId(id);
    if (val === "firstImage") {
      setValForSingleImg(val);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue( getCardImageAspectRatio?.data?.data?.setting_value)
    }
    if (val === "secondImage") {
      setMultipleImageSection(val);
      setValForSingleImg("");
      setValForIcon(null);
      setValForSingleImg3(null);
      setValForBannerImage(null);
      setSettingValue( getAspectRatio?.data?.data?.setting_value)
    }
    if (val === "thirdImage") {
      setImgArray(index);
      setValForSingleImg3(val);
      setMultipleImageSection(null);
      setValForSingleImg(null);
      setValForBannerImage(null);
      setSettingValue( getAspectRatio?.data?.data?.setting_value)
    }
    if(val=== "BannerImage"){
      setValForBannerImage(val);
      setValForSingleImg(null);
      setMultipleImageSection("");
      setValForSingleImg3(null);
      setSettingValue( getInnerImageAspectRatio?.data?.data?.setting_value)
    }
    if (val === "restaurantIcon") {
      setValForIcon(val);
      setValForSingleImg(null);
      setValForSingleImg3(null);
      setMultipleImageSection(null);
      setSettingValue( getAspectRatio?.data?.data?.setting_value)
    }
    setCropMediaModal(true);
  };
  const queryClient = useQueryClient();

  const [cropMediaModal, setCropMediaModal] = useState(false);
  const getAspectRatio = useQuery(
    ["AspectRatio", "dining_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_image_dimension")
  );

  const getInnerImageAspectRatio = useQuery(
    ["AspectRatio", "dining_inner_image_dimension"], // Using an array as the query key with offerId
    () => querydata.getAspectRatio("dining_inner_image_dimension")
  );

  const getCardImageAspectRatio = useQuery(
    ["AspectRatio", "dining_card_image_dimension"], // Using an array as the query key
    () => querydata.getAspectRatio("dining_card_image_dimension")
  );
  const handleRemoveImage = (e) => {
    // Handle removing the image
    setmainCroppedFile(null)
    if (e) {
      setImage(null);
      setmainCroppedImage(null);
    }
  };
  const handleBannerImage=(e)=>{
    setmainCroppedBannerImageFile(null);
    if(e){
      setBannerImage(null);
      setMainCroppedBannerImage(null);
    }

  }
  // const handleRemoveIcon = (e) => {
  //   // Handle removing the image
  //   setmainCroppedIconFile(null)
  //   if (e) {
  //     setIcon(null);
  //     setMainCroppedIcon(null);
  //   }
  // };
  const handleSecRemoveImage = (e) => {
    // Handle removing the image
    setmainCroppedSecFile(null)
    if (e) {
      setImage2(null);
      setmainCroppedSecImage(null);
    }
  };
  const handleThirdRemoveImage = (e, sectionId, index) => {
    if (maincroppedThirdFile === null) {
      return
    }
    const update = Object.values(maincroppedThirdFile)

    update[index] = null
    setmainCroppedThirdFile(update)
    setImage3((prevData) => {
      const updatedData = { ...prevData };
      delete updatedData[sectionId];
      return updatedData;
    });
  };
  const [featureDropdowns, setFeatureDropdowns] = useState([]);
  const [tagValue, setTagValue] = useState([]);
  const handleAddRestaurantTag = (index) => {
    const newDropdownKey = index.toString();
    setFeatureDropdowns((prevDropdowns) => [
      ...prevDropdowns,
      {
        key: Math.random() * 1000,
        options: "",

        onDelete: () => handleRemoveFeatureDropdown(newDropdownKey),
      },
    ]);
  };

  const handleRemoveFeatureDropdown = (dropdown) => {
    const parentDiv = document.querySelector(
      `.row.position-relative[data-key="${dropdown}"]`
    );
    if (parentDiv) {
      parentDiv.remove();
    }

    const data = values.RestaurantTags;
    const keyToRemove = dropdown; // You might get the key dynamically or hardcode it

    // Check if the key exists before removing
    if (data.hasOwnProperty(keyToRemove)) {
      delete data[keyToRemove];

      // updateDisplay();
    } else {
    }
  };
  const handleChangeRestaurantTag = (event, dropdownKey) => {
    setValues((prevValues) => {
      return {
        ...prevValues,
        RestaurantTags: {
          ...prevValues.RestaurantTags,
          [dropdownKey]: event.target.value,
        },
      };
    });
  };

  // crop code end
  const [restaurandDetailDataArray, setRestaurandDetailDataArray] = useState([
    {
      id: 1,
      name: "Cuisine",
      tagImage: "",
      tagHeading: "",
      tagDescription: "",
      tagFeatures: [],
    },
    {
      id: 2,
      name: "Ambience",
      tagImage: "",
      tagHeading: "",
      tagDescription: "",
      tagFeatures: [],
    },

  ]);

  const [initialValues, setInitialValues] = useState({
    RestaurantName: "",
    RestaurantTypeId: "",
    RestaurantTagLine: "",
    RestaurantTags: "",
    RestaurantDescription: "",
    dataArray: restaurandDetailDataArray,
  });

  const [dataArray, setDataArray] = useState(initialValues.dataArray);



  useEffect(() => {
    setDataArray((prevDataArray) => {

      return prevDataArray.map((data) => ({
        ...data,
        tagImage:
          maincroppedThirdFile && maincroppedThirdFile[`section_${data.id}`]
            ? maincroppedThirdFile[`section_${data.id}`]
            : null,
      }));
    });
  }, [maincroppedThirdFile]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: RestaurantSchema,
    onSubmit: (values, action) => {


      const formData = new FormData();
      formData.append("restaurantTypeId", values.RestaurantTypeId);
      formData.append("restaurantDescription", values.RestaurantDescription);
      formData.append("restaurantName", values.RestaurantName);
      formData.append("restaurantTagline", values.RestaurantTagLine)
      if (values.dataArray) {
        values.dataArray.forEach((data, index) => {
          const detailFeatures = Object.values(data.tagFeatures);

          const elementData = {
            heading: data.name,

            description: data.tagDescription,
            features: detailFeatures,
          };

          formData.append(
            `restaurantDetail[${index}]`,
            JSON.stringify(elementData)
          );
        });
      }
      if (values.RestaurantTags) {
        const restaurantArray = Object.values(values.RestaurantTags);

        restaurantArray.map((restTags, index) => {
          formData.append(`restaurantTags[${index}]`, restTags);
        });
      }

      if (maincroppedFile === null) {
        toast.dismiss();
        toast.error("Please upload banner image")
        return
      }
     if(mainCroppedBannerImageFile === null){
      toast.dismiss();
      toast.error("Please upload inner banner image")
      return
     }
      // if (maincroppedSecFile === null) {

      //   toast.error("Please upload menu activities ")
      //   return
      // }


      if (values.dataArray.length !== 0) {
        if (maincroppedThirdFile === null) {
          toast.dismiss();
          toast.error("Please upload tag image ")
          return
        }
        else {
          const detailImagesArrayFromObject = Object.values(maincroppedThirdFile);

          for (let i = 0; i < detailImagesArrayFromObject.length; i++) {
            if (detailImagesArrayFromObject[i] === null) {
              toast.dismiss();
              toast.error("Please upload tag image ")
              return


            }
          }




          if (values.dataArray.length !== detailImagesArrayFromObject.length) {
            toast.dismiss();
            toast.error("Please upload all tag image ")
            return
          }
        }
      }

      formData.append("menuImage", maincroppedSecFile);
      formData.append("restaurantImage", maincroppedFile);
     if(mainCroppedBannerImageFile){
      formData.append("innerBannerImage",mainCroppedBannerImageFile);
     }
     
      // formData.append("restaurantIcon", mainCroppedIconFile);
      if (values.dataArray.length !== 0) {
        if (maincroppedThirdFile) {

          const detailImagesArrayFromObject = Object.values(maincroppedThirdFile);

          detailImagesArrayFromObject.map((detailImage) => {
            if (detailImage !== null && detailImage !== undefined) {
              formData.append(`detailImages`, detailImage);

            }
          });

        }
      }

    // formData.forEach(function(key,value){
     
    // }
    // )


       addnewprojectMutation.mutate(formData);

    },
  });



  useEffect(() => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      dataArray: restaurandDetailDataArray,
    }));

    // Reset the form with new initial values
    formik.resetForm({
      values: {
        ...formik.values,
        dataArray: restaurandDetailDataArray,
      },
    });
  }, [restaurandDetailDataArray, formik.resetForm]);


  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    getFieldProps,
  } = formik;



  const addnewprojectMutation = useMutation(insertRestaurantData, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);

      toast.dismiss();
      toast.success(data.message);
      queryClient.resetQueries("getAllRestaurents")

      navigate("/manage-dining");
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const checkErrorAndSubmit = () => {
    if (Object.keys(errors).length > 0) {
      toast.dismiss();

      toast.error("Please fill all the fields ")
      handleSubmit()
    }
    else {
      handleSubmit()
    }
  }



  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <nav className="w-100" aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link className="text-decoration-none" to="/manage-dining">
                    Manage Dining
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Create Restaurant
                </li>
              </ol>
            </nav>
            <div className="left-header d-flex align-item-center">
              <Link
                to="/manage-dining"
                className="back-arrow d-inline-block me-2"
              >
                <img src={backarrow} alt="" />
              </Link>
              <h4 className="fw-normal m-0">Create Restaurant</h4>
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card mb-4">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <h5 className="m-0 fw-medium">Create Restaurant</h5>
                </div>
                <div className="card-body">
                  <div className="upload-file-wrapper">
                    <Form>
                      <Row>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Image <span className="mandatory">*</span>{" "}
                            </Form.Label>
                            {!maincroppedImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "firstImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={maincroppedImage} target="_blank">
                                    <img
                                      src={maincroppedImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleRemoveImage(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                            Inner Banner Image <span className="mandatory">*</span>
                            </Form.Label>
                            {!maincroppedBannerImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  for="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "BannerImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedBannerImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={maincroppedBannerImage} target="_blank">
                                    <img
                                      src={maincroppedBannerImage}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) =>
                                        handleBannerImage(e)
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}

                            {/* <p className="form-error text-danger">
                              {maincroppedImage ? "" : "Please enter image "}
                            </p> */}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Restaurant Name{" "}
                              <span className="mandatory">*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="py-3 shadow-none px-4"
                              value={values.RestaurantName}
                              onChange={handleChange}
                              name="RestaurantName"
                              onBlur={handleBlur}
                              maxLength={20}
                            />
                            {errors.RestaurantName && touched.RestaurantName ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantName}
                              </p>
                            ) : error.RestaurantName ? (
                              <p className="form-error text-danger">
                                {error.RestaurantName}
                              </p>
                            ) : !errors.description &&
                              error.RestaurantName &&
                              touched.RestaurantName ? (
                              <p className="form-error text-danger">
                                Please enter offer restaurant name
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              {" "}
                              Restaurant Type{" "}
                              {/* <span className="mandatory">*</span> */}
                            </Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              className="py-3 shadow-none px-4"
                              onChange={handleChange}
                              value={values.RestaurantTypeId}
                              name="RestaurantTypeId"
                              onBlur={handleBlur}
                            >
                              <option value={""}>---Select---</option>
                              {AllRestaurantTypeId.data &&
                                AllRestaurantTypeId.data.data.map((option) => (
                                  <option
                                    key={option.restaurant_type_id}
                                    value={option.restaurant_type_id}
                                  >
                                    {option.restaurant_type}
                                  </option>
                                ))}
                            </Form.Select>
                            {errors.RestaurantTypeId &&
                              touched.RestaurantTypeId ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTypeId}
                              </p>
                            ) : errors.RestaurantTypeId &&
                              touched.RestaurantTypeId ? (
                              <p className="form-error text-danger"></p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Tag Line <span className="mandatory">*</span>
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              value={values.RestaurantTagLine}
                              onChange={(e) =>
                                // test2(e)
                                setFieldValue(
                                  "RestaurantTagLine",
                                  e.target.value
                                )
                              }
                              name="RestaurantTagLine"
                            // maxLength={30}
                            />
                            {errors.RestaurantTagLine &&
                              touched.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantTagLine}
                              </p>
                            ) : error.RestaurantTagLine ? (
                              <p className="form-error text-danger">
                                {error.RestaurantTagLine}
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Description <span className="mandatory">*</span>{" "}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              style={{ height: "100px" }}
                              onChange={handleChange}
                              value={values.RestaurantDescription}
                              name="RestaurantDescription"
                              onBlur={handleBlur}
                            // maxLength={30}
                            />
                            {errors.RestaurantDescription &&
                              touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {errors.RestaurantDescription}
                              </p>
                            ) : error.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                {error.RestaurantDescription}
                              </p>
                            ) : !errors.RestaurantDescription &&
                              error.RestaurantDescription &&
                              touched.RestaurantDescription ? (
                              <p className="form-error text-danger">
                                Please select a restaurant description
                              </p>
                            ) : null}
                          </Form.Group>
                        </Col>
                        {/* <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>
                              Restaurant Icon
                            </Form.Label>
                            {!mainCroppedIcon && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "restaurantIcon")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {mainCroppedIconFile && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div>
                                  <a href={mainCroppedIcon} target="_blank">
                                    <img
                                      src={mainCroppedIcon}
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleRemoveIcon(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col> */}
                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Show Menu/Activities</Form.Label>
                            {!maincroppedSecImage && (
                              <div className="uploaded-property-img d-flex">
                                <label
                                  htmlFor="UploadCover"
                                  className="mt-2 upload-label position-relative w-100 rounded-3 px-4 py-5"
                                  onClick={() =>
                                    showCropMediaModal("", "secondImage")
                                  }
                                >
                                  <div className="m-auto rounded-1 d-flex align-items-center justify-content-center">
                                    <Button
                                      type="button"
                                      className="btn btn-primary btn-theme fw-semibold text-uppercase px-4 py-3"
                                    >
                                      Choose menu from gallery
                                    </Button>
                                  </div>
                                </label>
                              </div>
                            )}
                            {maincroppedSecImage && (
                              <div
                                className="uploaded-img-view"
                                style={{
                                  height: "200px",
                                  width: "200px",
                                  position: "relative",
                                }}
                              >
                                <div
                                  style={
                                    maincroppedSecImage.endsWith(".pdf")
                                      ? {
                                        width: "68%",
                                        height: "68%",
                                      }
                                      : { width: "100%", height: "100%" }
                                  }
                                >
                                  <a
                                    href={
                                      maincroppedSecImage.endsWith(".pdf")
                                        ? pdfImg
                                        : maincroppedSecImage
                                    }
                                    target="_blank"
                                  >
                                    <img
                                      src={
                                        maincroppedSecImage.endsWith(".pdf")
                                          ? pdfImg
                                          : maincroppedSecImage
                                      }
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                        // objectFit: "cover",
                                      }}
                                    />
                                  </a>
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: "0",
                                      right: "0",
                                    }}
                                  >
                                    <button
                                      className="remove-image-button"
                                      onClick={(e) => handleSecRemoveImage(e)}
                                    >
                                      <i className="fa fa-times"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Form.Group>
                        </Col>

                        <Col lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Restaurant Tags</Form.Label>

                            <RestaurentTags
                              setFieldValue={setFieldValue}
                              name={getFieldProps("RestaurantTags").name}
                              value={getFieldProps("RestaurantTags").value}
                              maxLength={20}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>

                    <RestaurantDetail
                      handleThirdRemoveImage={handleThirdRemoveImage}
                      handleRemoveImage={handleRemoveImage}
                      showCropMediaModal={showCropMediaModal}
                      image={image}
                      image3={image3}
                      maincroppedThirdImage={maincroppedThirdImage}
                      // dataArray={dataArray}
                      setDataArray={setDataArray}
                      values={values}
                      handleChange={handleChange}
                      setValues={setValues}
                      getFieldProps={getFieldProps}
                      setFieldValue={setFieldValue}
                      // setDetailArray={setDetailArray}
                      setRestaurandDetailDataArray={
                        setRestaurandDetailDataArray
                      }
                      maincroppedThirdFile={maincroppedThirdFile}
                      setmainCroppedThirdFile={setmainCroppedThirdFile}

                      initialValues={initialValues}
                      restaurandDetailDataArray={restaurandDetailDataArray}
                      formik={formik}
                    />

                    <div className="save-btn text-end mt-4">
                      <Button
                        className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                        onClick={() => checkErrorAndSubmit()}
                      >
                        {isLoading ? <div class="spinner-border text-primary" role="status"></div> : 'SAVE'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="xl" show={cropMediaModal} onHide={closeCropMediaModal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">
            {"Select Image"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" overflow-x-auto overflow-y-auto">
          {!folderId && <MediaFolderView setFolderId={setFolderId} />}
          {folderId && !albumId && !websiteId && (
            <ManageAlbumSelection
              folderId={folderId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
            />
          )}
          {folderId && albumId && websiteId && (
            <AlbumViewForDinning
              dataCommingFrom="add"
              maincroppedThirdFile={maincroppedThirdFile}
              imgArray={imgArray}

              currentFileId={currentFileId}
              albumId={albumId}
              websiteId={websiteId}
              setAlbumId={setAlbumId}
              setwebsiteId={setwebsiteId}
              setFolderId={setFolderId}
              setCropMediaModal1={setCropMediaModal}
              setmainCroppedSecImage={setmainCroppedSecImage}
              setMainCroppedIcon={setMainCroppedIcon}
              setmainCroppedIconFile={setmainCroppedIconFile}
              setmainCroppedSecFile={setmainCroppedSecFile}
              setmainCroppedThirdFile={setmainCroppedThirdFile}
              setmainCroppedThirdImage={setImage3}
              setmainCroppedImage={setmainCroppedImage}
              checkValForIcon={checkValForIcon}
              maincroppedImage={maincroppedImage}
              checkValForSingleImg={checkValForSingleImg}
              checkValForSingleImg3={checkValForSingleImg3}
              checkMultipleImageSection={checkMultipleImageSection}
              setmainCroppedFile={setmainCroppedFile}
              setMainCroppedBannerImage={setMainCroppedBannerImage}
              checkValForBannerImage={checkValForBannerImage}
              setmainCroppedBannerImageFile={setmainCroppedBannerImageFile}
              // setting_value={
              //   getAspectRatio &&
              //   getAspectRatio.data &&
              //   getAspectRatio.data.data &&
              //   getAspectRatio.data.data.setting_value
              // }
              setting_value={settingValue}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddNewRestaurant;
