import React, { useState } from "react";
import albumPng from "../../assets/images/folder.png";

import  { components } from "react-select";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import * as query from "../../services/service";
import Pagination from "../../components/Pagination";

const { Option } = components;

const MultiValueLabel = (props) => (
  <Option {...props}>
    <input type="checkbox" checked={props.isSelected} onChange={() => null} />
    {props.label}
  </Option>
);

function MediaFolderView() {
  
  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if this cookie is the one we are looking for
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1);
      }
    }
    // Return null if the cookie is not found
    return null;
  };
  const websiteIdFromCookie = getCookie("websiteId");

  const getMediaFolderData = useQuery(
    ["getMediaFolderData",+websiteIdFromCookie],
    ()=>query.getMediaFolder(websiteIdFromCookie)
  );
 

  const { websiteId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [show, setShow] = useState(false);

  const itemsPerPage = 10; // Number of items to display per page
  const totalPages = Math.ceil(
    getMediaFolderData?.data?.data.length / itemsPerPage
  );
  const handlePageChange = (newPage) => {
    // Validate newPage to ensure it's within the valid range of pages
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // Ensure that data is an array before using slice

  const dataArray = Array.isArray(getMediaFolderData?.data?.data)
    ? getMediaFolderData?.data?.data
    : [];
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const mediaFolderData = dataArray.slice(startIndex, endIndex);
  const totalItems = dataArray.length;
  return (
    <>
      <div className="page-body">
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="left-header">
              <h4 className="fw-normal m-0">Gallery</h4>
            </div>
            <div className="header-action">
             
            </div>
          </div>
          <div className="row mt-lg-5 mt-md-4 mt-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                  <div className="left-header">
                    <div className="atmosphere-hr">
                      
                    </div>
                  </div>
                  <div className="right-action d-flex">
                    <div className="atmosphere-hr ms-2 me-2">
                      <div className="position-relative multiselect-box ">
                        <div className="ms-options-wrap">
                         
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </div>
                <div className="card-body">
                  <div className="gallery-assets">
                    <ul className="list-unstyled m-0 p-0 d-flex flex-wrap align-items-center">
                      {mediaFolderData &&
                        mediaFolderData.map((data) => (
                          <li key={data.website_id}>
                            <div className="album-view border rounded-3 position-relative overflow-hidden">
                              <div className="album-set-icon">
                                <div className="edit-album-cover lh-1 position-absolute end-0 top-0 m-3 z-2">
                                </div>
                                <Link
                                  to={`/manage-folder/manage-media/${data.website_id}`}
                                  className="position-relative text-decoration-none text-white d-flex px-2 py-3"
                                >
                                  <img
                                    src={albumPng}
                                    alt="Album"
                                    title="Album"
                                  />
                                  <div className="album-title position-absolute start-0 w-100 z-1 bottom-0 py-3 px-3 text-center">
                                    <h6 className="mb-0">
                                      {data.website_folder || "No Folder"}
                                    </h6>
                                    <div className="created-info mt-3 d-flex justify-content-between align-items-center">
                                      <span>By-: John Smith</span>
                                      <span>On-: 15/10/2023 10:30AM</span>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                    <div className="atmosphere-pagination album-pagination mt-4 d-flex justify-content-end">
                      <nav aria-label="...">
                        <ul className="pagination m-0 d-flex justify-content-end">
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            onPageChange={handlePageChange}
                            visiblePages={itemsPerPage}
                            totalItems={totalItems}
                          />
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaFolderView;
